import 'whatwg-fetch'
import React from 'react' /*eslint-disable-line */
const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function call(url, method, data) {
  const serverUrl = `${SERVER_URL}${url}`
  const token = storage.get('user_token')
  return fetch(serverUrl, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })
    .then(parseJSON)
    .then(checkHttpStatus)
    .catch(error => {
      // No response from the server
      if (typeof error.response === 'undefined') {
        error.response = {
          status: 408,
          message: 'Cannot connect to the server'
        }
      }
      throw error
    })
}

export function callForm(url, method, data) {
  const formData = convertToFormData(data)
  const serverUrl = `${SERVER_URL}${url}`
  const token = storage.get('user_token')
  return fetch(serverUrl, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: formData
  })
    .then(parseJSON)
    .then(checkHttpStatus)
    .catch(error => {
      // No response from the server
      if (typeof error.response === 'undefined') {
        error.response = {
          status: 408,
          message: 'Cannot connect to the server'
        }
      }
      throw error
    })
}

const convertToFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return formData
}

export function get(url) {
  return call(url, 'GET')
}

export function post(url, data) {
  return call(url, 'POST', data)
}

export function put(url, data) {
  return call(url, 'PUT', data)
}

export function del(url, data) {
  return call(url, 'DELETE', data)
}

export function patch(url, data) {
  return call(url, 'PATCH', data)
}

export function patchForm(url, data) {
  return callForm(url, 'PATCH', data)
}

export function postForm(url, data) {
  return callForm(url, 'POST', data)
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.body
  }
     else {
    var error = new Error(response.statusText)
    error.response = response.body
    error.status = response.status
    throw error
  }
}

export function parseJSON(response) {
  return response.json()
    .then(function (body) {
      return {
        status: response.status,
        statusText: response.statusText,
        body: body
      }
    })
    .catch(function(e) {
      return response;
    })
}

export const storage = {
  get: function (k) {
    return localStorage.getItem(k);
  },
  set: function (k, v) {
    localStorage.setItem(k, v);
  },
  remove: function (k) {
    localStorage.removeItem(k)
  }
}

export const FETCH_STATUS_REQUEST = "request"
export const FETCH_STATUS_SUCCESS = "success"
export const FETCH_STATUS_FAILURE = "failure"
export const FETCH_STATUS_IDLE = "idle"

export function base64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
