import produce from "immer"

import {
  CREATE_STOCK_REQUEST,
  CREATE_STOCK_SUCCESS,
  CREATE_STOCK_FAILURE,
  CREATE_STOCK_IDLE,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_FAILURE,
  UPDATE_STOCK_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  createStock: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  updateStock: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const stockFormPage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STOCK_REQUEST: {
      return produce(state, draft => {
        draft.createStock.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_STOCK_SUCCESS: {
      return produce(state, draft => {
        draft.createStock.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_STOCK_FAILURE: {
      return produce(state, draft => {
        draft.createStock.fetchStatus = FETCH_STATUS_FAILURE
        draft.createStock.error = action.payload.error
      })
    }
    case CREATE_STOCK_IDLE: {
      return produce(state, draft => {
        draft.createStock.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_STOCK_REQUEST: {
      return produce(state, draft => {
        draft.updateStock.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_STOCK_SUCCESS: {
      return produce(state, draft => {
        draft.updateStock.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case UPDATE_STOCK_FAILURE: {
      return produce(state, draft => {
        draft.updateStock.fetchStatus = FETCH_STATUS_FAILURE
        draft.updateStock.error = action.payload.error
      })
    }
    case UPDATE_STOCK_IDLE: {
      return produce(state, draft => {
        draft.updateStock.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default stockFormPage
