import produce from "immer"

import {
  SET_MESSAGE
} from './actions'

const initialState = {
  message: "Hello world"
}

const application = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE: {
      return produce(state, draft => {
        draft.message = action.payload.message
      })
    }
    default: {
      return state
    }
  }
}

export default application
