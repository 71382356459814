import produce from "immer"

import {
  GET_DASHBOARD_GRAPH_DATA_REQUEST,
  GET_DASHBOARD_GRAPH_DATA_SUCCESS,
  GET_DASHBOARD_GRAPH_DATA_FAILURE,
  GET_DASHBOARD_GRAPH_DATA_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  saleGraphData: [],
  saleRankData: [],
  getDashboardGraphData: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const loginPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_GRAPH_DATA_REQUEST: {
      return produce(state, draft => {
        draft.getDashboardGraphData.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case GET_DASHBOARD_GRAPH_DATA_SUCCESS: {
      return produce(state, draft => {
        draft.getDashboardGraphData.fetchStatus = FETCH_STATUS_SUCCESS
        draft.saleGraphData = action.payload.saleGraphData
        draft.saleRankData = action.payload.saleRankData.sort((a, b) => b-a)
      })
    }
    case GET_DASHBOARD_GRAPH_DATA_FAILURE: {
      return produce(state, draft => {
        draft.getDashboardGraphData.fetchStatus = FETCH_STATUS_FAILURE
        draft.getDashboardGraphData.error = action.payload.error
      })
    }
    case GET_DASHBOARD_GRAPH_DATA_IDLE: {
      return produce(state, draft => {
        draft.getDashboardGraphData.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default loginPage
