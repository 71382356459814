import queryString from 'query-string'

import { get, patch } from '../../utils'

export const LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_REQUEST = "LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_REQUEST"
export const LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_SUCCESS = "LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_SUCCESS"
export const LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_FAILURE = "LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_FAILURE"
export const LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_IDLE = "LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_IDLE"

const loadListPurchaseOrderFilterRangeRequest = (filterType) => {
  return {
    type: LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_REQUEST,
    payload: {
      filterType
    }
  }
}

const loadListPurchaseOrderFilterRangeSuccess = (listPurchaseOrder) => {
  return {
    type: LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_SUCCESS,
    payload: {
      listPurchaseOrder
    }
  }
}

const loadListPurchaseOrderFilterRangeFailure = (error) => {
  return {
    type: LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_FAILURE,
    payload: {
      error
    }
  }
}

const loadListPurchaseOrderFilterRangeIdle = () => {
  return {
    type: LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_IDLE
  }
}

export const loadListPurchaseOrderFilterRange = ({ filterType, start_time, end_time}) => {
  return async dispatch => {
    dispatch(loadListPurchaseOrderFilterRangeRequest(filterType))
    try {
      const query = queryString.stringify({
        start_time, end_time
      })
      const listPurchaseOrder = await get(`/order/${filterType}?${query}`)
      dispatch(loadListPurchaseOrderFilterRangeSuccess(listPurchaseOrder))
    }
    catch (err) {
      dispatch(loadListPurchaseOrderFilterRangeRequest(err))
    }
    finally{
      dispatch(loadListPurchaseOrderFilterRangeIdle())
    }
  }
}

export const UPDATE_PURCHASE_ORDER_STATUS_REQUEST = "UPDATE_PURCHASE_ORDER_STATUS_REQUEST"
export const UPDATE_PURCHASE_ORDER_STATUS_SUCCESS = "UPDATE_PURCHASE_ORDER_STATUS_SUCCESS"
export const UPDATE_PURCHASE_ORDER_STATUS_FAILURE = "UPDATE_PURCHASE_ORDER_STATUS_FAILURE"
export const UPDATE_PURCHASE_ORDER_STATUS_IDLE = "UPDATE_PURCHASE_ORDER_STATUS_IDLE"

const updatePurchaseOrderStatusRequest = () => {
  return {
    type: UPDATE_PURCHASE_ORDER_STATUS_REQUEST
  }
}

const updatePurchaseOrderStatusSuccess = (purchaseOrderData) => {
  return {
    type: UPDATE_PURCHASE_ORDER_STATUS_SUCCESS,
    payload: {
      purchaseOrderData
    }
  }
}

const updatePurchaseOrderStatusFailure = (error) => {
  return {
    type: UPDATE_PURCHASE_ORDER_STATUS_FAILURE,
    payload: {
      error
    }
  }
}

const updatePurchaseOrderStatusIdle = () => {
  return {
    type: UPDATE_PURCHASE_ORDER_STATUS_IDLE
  }
}

export const updatePurchaseOrderStatus = (purchaseOrderData, data) => {
  return async dispatch => {
    dispatch(updatePurchaseOrderStatusRequest())
    try {
      await patch(`/order/${purchaseOrderData.id}/status`, data)
      dispatch(updatePurchaseOrderStatusSuccess(purchaseOrderData))
    }
    catch (err) {
      dispatch(updatePurchaseOrderStatusRequest(err))
    }
    finally{
      dispatch(updatePurchaseOrderStatusIdle())
    }
  }
}

export const LOCK_STOCK_PURHCASE_ORDER_REQUEST = "LOCK_STOCK_PURHCASE_ORDER_REQUEST"
export const LOCK_STOCK_PURHCASE_ORDER_SUCCESS = "LOCK_STOCK_PURHCASE_ORDER_SUCCESS"
export const LOCK_STOCK_PURHCASE_ORDER_FAILURE = "LOCK_STOCK_PURHCASE_ORDER_FAILURE"
export const LOCK_STOCK_PURHCASE_ORDER_IDLE = "LOCK_STOCK_PURHCASE_ORDER_IDLE"

const lockStockPurchaseOrderRequest = (purchaseOrderData) => {
  return {
    type: LOCK_STOCK_PURHCASE_ORDER_REQUEST,
    payload: {
      purchaseOrderData
    }
  }
}

const lockStockPurchaseOrderSuccess = (purchaseOrderData) => {
  return {
    type: LOCK_STOCK_PURHCASE_ORDER_SUCCESS,
    payload: {
      purchaseOrderData
    }
  }
}

const lockStockPurchaseOrderFailure = (error) => {
  return {
    type: LOCK_STOCK_PURHCASE_ORDER_FAILURE,
    payload: {
      error
    }
  }
}

const lockStockPurchaseOrderIdle = () => {
  return {
    type: LOCK_STOCK_PURHCASE_ORDER_IDLE
  }
}

export const lockStockPurchaseOrder = (purchaseOrderData) => {
  return async dispatch => {
    dispatch(lockStockPurchaseOrderRequest(purchaseOrderData))
    try {
      await patch(`/order/${purchaseOrderData.id}/lock_stock`)
      dispatch(lockStockPurchaseOrderSuccess(purchaseOrderData))
    }
    catch (err) {
      dispatch(lockStockPurchaseOrderFailure(err))
    }
    finally{
      dispatch(lockStockPurchaseOrderIdle())
    }
  }
}

export const UPDATE_MESSENGER_PURHASE_ORDER_REQUEST = "UPDATE_MESSENGER_PURHASE_ORDER_REQUEST"
export const UPDATE_MESSENGER_PURHASE_ORDER_SUCCESS = "UPDATE_MESSENGER_PURHASE_ORDER_SUCCESS"
export const UPDATE_MESSENGER_PURHASE_ORDER_FAILURE = "UPDATE_MESSENGER_PURHASE_ORDER_FAILURE"
export const UPDATE_MESSENGER_PURHASE_ORDER_IDLE = "UPDATE_MESSENGER_PURHASE_ORDER_IDLE"

const updateMessengerPurchaseOrderRequest = () => {
  return {
    type: UPDATE_MESSENGER_PURHASE_ORDER_REQUEST
  }
}

const updateMessengerPurchaseOrderSuccess = (purchaseOrderData, { messenger }) => {
  return {
    type: UPDATE_MESSENGER_PURHASE_ORDER_SUCCESS,
    payload: {
      purchaseOrderData,
      messenger
    }
  }
}

const updateMessengerPurchaseOrderFailure = (error) => {
  return {
    type: UPDATE_MESSENGER_PURHASE_ORDER_FAILURE,
    payload: {
      error
    }
  }
}

const updateMessengerPurchaseOrderIdle = () => {
  return {
    type: UPDATE_MESSENGER_PURHASE_ORDER_IDLE
  }
}

export const updateMessengerPurchaseOrder = (data, purchaseOrder) => {
  return async dispatch => {
    dispatch(updateMessengerPurchaseOrderRequest())
    try {
      const response = await patch(`/order/${purchaseOrder.id}/messenger`, data)
      dispatch(updateMessengerPurchaseOrderSuccess(purchaseOrder, data))
    }
    catch (err) {
      dispatch(updateMessengerPurchaseOrderFailure(err))
    }
    finally {
      dispatch(updateMessengerPurchaseOrderIdle())
    }
  }
}

export const CREATE_PURCHASE_ORDER_SOCKET_REQUEST = "CREATE_PURCHASE_ORDER_SOCKET_REQUEST"
export const CREATE_PURCHASE_ORDER_SOCKET_SUCCESS = "CREATE_PURCHASE_ORDER_SOCKET_SUCCESS"
export const CREATE_PURCHASE_ORDER_SOCKET_IDLE = "CREATE_PURCHASE_ORDER_SOCKET_IDLE"

export const createPurchaseOrderSocketRequest = () => {
  return {
    type: CREATE_PURCHASE_ORDER_SOCKET_REQUEST
  }
}

export const createPurchaseOrderSocketSuccess = (purchaseOrderData) => {
  return {
    type: CREATE_PURCHASE_ORDER_SOCKET_SUCCESS,
    payload: {
      purchaseOrderData
    }
  }
}

export const createPurchaseOrderSocketIdle = () => {
  return {
    type: CREATE_PURCHASE_ORDER_SOCKET_IDLE
  }
}

export const createPurchaseOrderSocket = (purchaseOrderData) => {
  return async dispatch => {
    dispatch(createPurchaseOrderSocketRequest())
    dispatch(createPurchaseOrderSocketSuccess(purchaseOrderData))
    dispatch(createPurchaseOrderSocketIdle())
  }
}

export const UPDATE_PURCHASE_ORDER_SOCKET_REQUEST = "UPDATE_PURCHASE_ORDER_SOCKET_REQUEST"
export const UPDATE_PURCHASE_ORDER_SOCKET_SUCCESS = "UPDATE_PURCHASE_ORDER_SOCKET_SUCCESS"
export const UPDATE_PURCHASE_ORDER_SOCKET_IDLE = "UPDATE_PURCHASE_ORDER_SOCKET_IDLE"

export const updatePurchaseOrderSocketRequest = () => {
  return {
    type: UPDATE_PURCHASE_ORDER_SOCKET_REQUEST
  }
}

export const updatePurchaseOrderSocketSuccess = (purchaseOrderData) => {
  return {
    type: UPDATE_PURCHASE_ORDER_SOCKET_SUCCESS,
    payload: {
      purchaseOrderData
    }
  }
}

export const updatePurchaseOrderSocketIdle = () => {
  return {
    type: UPDATE_PURCHASE_ORDER_SOCKET_IDLE
  }
}

export const updatePurchaseOrderSocket = (purchaseOrderData) => {
  return async dispatch => {
    dispatch(updatePurchaseOrderSocketRequest())
    dispatch(updatePurchaseOrderSocketSuccess(purchaseOrderData))
    dispatch(updatePurchaseOrderSocketIdle())
  }
}
