import { get, del } from '../../utils'

export const GET_LIST_STOCK_REQUEST = "GET_LIST_STOCK_REQUEST"
export const GET_LIST_STOCK_SUCCESS = "GET_LIST_STOCK_SUCCESS"
export const GET_LIST_STOCK_FAILURE = "GET_LIST_STOCK_FAILURE"
export const GET_LIST_STOCK_IDLE = "GET_LIST_STOCK_IDLE"

const getListStockRequest = () => {
  return {
    type: GET_LIST_STOCK_REQUEST
  }
}

const getListStockSuccess = (listStock) => {
  return {
    type: GET_LIST_STOCK_SUCCESS,
    payload: {
      listStock
    }
  }
}

const getListStockFailure = (error) => {
  return {
    type: GET_LIST_STOCK_FAILURE,
    payload: {
      error
    }
  }
}

const getListStockIdle = () => {
  return {
    type: GET_LIST_STOCK_IDLE
  }
}

export const getListStock = () => {
  return async dispatch => {
    dispatch(getListStockRequest())
    try {
      const response = await get(`/sku`)      
      dispatch(getListStockSuccess(response))
    }
    catch (err) {
      dispatch(getListStockFailure(err))
    }
    finally {
      dispatch(getListStockIdle())
    }
  }
}

export const DELETE_STOCK_REQUEST = "DELETE_STOCK_REQUEST"
export const DELETE_STOCK_SUCCESS = "DELETE_STOCK_SUCCESS"
export const DELETE_STOCK_FAILURE = "DELETE_STOCK_FAILURE"
export const DELETE_STOCK_IDLE = "DELETE_STOCK_IDLE"

const deleteStockRequest = () => {
  return {
    type: DELETE_STOCK_REQUEST
  }
}

const deleteStockSuccess = (stockData) => {
  return {
    type: DELETE_STOCK_SUCCESS,
    payload: {
      stockData
    }
  }
}

const deleteStockFailure = (error) => {
  return {
    type: DELETE_STOCK_FAILURE,
    payload: {
      error
    }
  }
}

const deleteStockIdle = () => {
  return {
    type: DELETE_STOCK_IDLE
  }
}

export const deleteStock = (data) => {
  return async dispatch => {
    dispatch(deleteStockRequest())
    try {
      await del(`/stock/${data.id}`)      
      dispatch(deleteStockSuccess(data))
    }
    catch (err) {
      dispatch(deleteStockFailure(err))
    }
    finally {
      dispatch(deleteStockIdle())
    }
  }
}
