import { postForm, patchForm } from '../../utils'

export const CREATE_STOCK_REQUEST = "CREATE_STOCK_REQUEST"
export const CREATE_STOCK_SUCCESS = "CREATE_STOCK_SUCCESS"
export const CREATE_STOCK_FAILURE = "CREATE_STOCK_FAILURE"
export const CREATE_STOCK_IDLE = "CREATE_STOCK_IDLE"

const createStockRequest = () => {
  return {
    type: CREATE_STOCK_REQUEST
  }
}

const createStockSuccess = (createdStock) => {
  return {
    type: CREATE_STOCK_SUCCESS,
    payload: {
      createdStock
    }
  }
}

const createStockFailure = (error) => {
  return {
    type: CREATE_STOCK_FAILURE,
    payload: {
      error
    }
  }
}

const createStockIdle = () => {
  return {
    type: CREATE_STOCK_IDLE
  }
}

export const createStock = (data) => {
  return async dispatch => {
    dispatch(createStockRequest())
    try {
      const response = await postForm(`/sku`, data)
      dispatch(createStockSuccess(response))
    }
    catch (err) {
      dispatch(createStockFailure(err))
    }
    finally {
      dispatch(createStockIdle())
    }
  }
}

export const UPDATE_STOCK_REQUEST = "UPDATE_STOCK_REQUEST"
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS"
export const UPDATE_STOCK_FAILURE = "UPDATE_STOCK_FAILURE"
export const UPDATE_STOCK_IDLE = "UPDATE_STOCK_IDLE"

const updateStockRequest = () => {
  return {
    type: UPDATE_STOCK_REQUEST
  }
}

const updateStockSuccess = (updatedStock) => {
  return {
    type: UPDATE_STOCK_SUCCESS,
    payload: {
      updatedStock
    }
  }
}

const updateStockFailure = (error) => {
  return {
    type: UPDATE_STOCK_FAILURE,
    payload: {
      error
    }
  }
}

const updateStockIdle = () => {
  return {
    type: UPDATE_STOCK_IDLE
  }
}

export const updateStock = (data) => {
  return async dispatch => {
    dispatch(updateStockRequest())
    try {
      const response = await patchForm(`/sku/${data.id}`, data)
      dispatch(updateStockSuccess(response))
    }
    catch (err) {
      dispatch(updateStockFailure(err))
    }
    finally {
      dispatch(updateStockIdle())
    }
  }
}
