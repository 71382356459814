import { post, patch, del } from '../../utils'

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST"
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS"
export const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE"
export const CREATE_CUSTOMER_IDLE = "CREATE_CUSTOMER_IDLE"

const createCustomerRequest = () => {
  return {
    type: CREATE_CUSTOMER_REQUEST
  }
}

const createCustomerSuccess = (customerData) => {
  return {
    type: CREATE_CUSTOMER_SUCCESS,
    payload: {
      customerData
    }
  }
}

const createCustomerFailure = (error) => {
  return {
    type: CREATE_CUSTOMER_FAILURE,
    payload: {
      error
    }
  }
}

const createCustomerIdle = () => {
  return {
    type: CREATE_CUSTOMER_IDLE
  }
}

export const createCustomer = (data) => {
  return async dispatch => {
    dispatch(createCustomerRequest())
    try {
      const response = await post(`/customer`, data)
      dispatch(createCustomerSuccess(response))
    }
    catch (err) {
      dispatch(createCustomerFailure(err))
    }
    finally {
      dispatch(createCustomerIdle())
    }
  }
}

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE"
export const UPDATE_CUSTOMER_IDLE = "UPDATE_CUSTOMER_IDLE"

const updateCustomerRequest = () => {
  return {
    type: UPDATE_CUSTOMER_REQUEST
  }
}

const updateCustomerSuccess = (customerData) => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: {
      customerData
    }
  }
}

const updateCustomerFailure = (error) => {
  return {
    type: UPDATE_CUSTOMER_FAILURE,
    payload: {
      error
    }
  }
}

const updateCustomerIdle = () => {
  return {
    type: UPDATE_CUSTOMER_IDLE
  }
}

export const updateCustomer = (data) => {
  return async dispatch => {
    dispatch(updateCustomerRequest())
    try {
      const response = await patch(`/customer/${data.id}`, data)
      dispatch(updateCustomerSuccess(data))
    }
    catch (err) {
      dispatch(updateCustomerFailure(err))
    }
    finally {
      dispatch(updateCustomerIdle())
    }
  }
}

export const CREATE_CUSTOMER_ADDRESS_REQUEST = "CREATE_CUSTOMER_ADDRESS_REQUEST"
export const CREATE_CUSTOMER_ADDRESS_SUCCESS = "CREATE_CUSTOMER_ADDRESS_SUCCESS"
export const CREATE_CUSTOMER_ADDRESS_FAILURE = "CREATE_CUSTOMER_ADDRESS_FAILURE"
export const CREATE_CUSTOMER_ADDRESS_IDLE = "CREATE_CUSTOMER_ADDRESS_IDLE"

const createCustomerAddressRequest = () => {
  return {
    type: CREATE_CUSTOMER_ADDRESS_REQUEST
  }
}

const createCustomerAddressSuccess = (address) => {
  return {
    type: CREATE_CUSTOMER_ADDRESS_SUCCESS,
    payload: {
      address
    }
  }
}

const createCustomerAddressFailure = (error) => {
  return {
    type: CREATE_CUSTOMER_ADDRESS_FAILURE,
    payload: {
      error
    }
  }
}

const createCustomerAddressIdle = () => {
  return {
    type: CREATE_CUSTOMER_ADDRESS_IDLE
  }
}

export const createCustomerAddress = (data, customerID) => {
  return async dispatch => {
    dispatch(createCustomerAddressRequest())
    try {
      const response = await post(`/customer/${customerID}/address`, data)
      dispatch(createCustomerAddressSuccess(response))
    }
    catch (err) {
      dispatch(createCustomerAddressFailure(err))
    }
    finally {
      dispatch(createCustomerAddressIdle())
    }
  }
}

export const UPDATE_CUSTOMER_ADDRESS_REQUEST = "UPDATE_CUSTOMER_ADDRESS_REQUEST"
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS = "UPDATE_CUSTOMER_ADDRESS_SUCCESS"
export const UPDATE_CUSTOMER_ADDRESS_FAILURE = "UPDATE_CUSTOMER_ADDRESS_FAILURE"
export const UPDATE_CUSTOMER_ADDRESS_IDLE = "UPDATE_CUSTOMER_ADDRESS_IDLE"

const updateCustomerAddressRequest = () => {
  return {
    type: UPDATE_CUSTOMER_ADDRESS_REQUEST
  }
}

const updateCustomerAddressSuccess = (address) => {
  return {
    type: UPDATE_CUSTOMER_ADDRESS_SUCCESS,
    payload: {
      address
    }
  }
}

const updateCustomerAddressFailure = (error) => {
  return {
    type: UPDATE_CUSTOMER_ADDRESS_FAILURE,
    payload: {
      error
    }
  }
}

const updateCustomerAddressIdle = () => {
  return {
    type: UPDATE_CUSTOMER_ADDRESS_IDLE
  }
}

export const updateCustomerAddress = (data) => {
  return async dispatch => {
    dispatch(updateCustomerAddressRequest())
    try {
      const response = await patch(`/customer/address/${data.id}`, data)
      dispatch(updateCustomerAddressSuccess(data))
    }
    catch (err) {
      dispatch(updateCustomerAddressFailure(err))
    }
    finally {
      dispatch(updateCustomerAddressIdle())
    }
  }
}

export const DELETE_CUSTOMER_ADDRESS_REQUEST = "DELETE_CUSTOMER_ADDRESS_REQUEST"
export const DELETE_CUSTOMER_ADDRESS_SUCCESS = "DELETE_CUSTOMER_ADDRESS_SUCCESS"
export const DELETE_CUSTOMER_ADDRESS_FAILURE = "DELETE_CUSTOMER_ADDRESS_FAILURE"
export const DELETE_CUSTOMER_ADDRESS_IDLE = "DELETE_CUSTOMER_ADDRESS_IDLE"

const deleteCustomerAddressRequest = () => {
  return {
    type: DELETE_CUSTOMER_ADDRESS_REQUEST
  }
}

const deleteCustomerAddressSuccess = (address) => {
  return {
    type: DELETE_CUSTOMER_ADDRESS_SUCCESS,
    payload: {
      address
    }
  }
}

const deleteCustomerAddressFailure = (error) => {
  return {
    type: DELETE_CUSTOMER_ADDRESS_FAILURE,
    payload: {
      error
    }
  }
}

const deleteCustomerAddressIdle = () => {
  return {
    type: DELETE_CUSTOMER_ADDRESS_IDLE
  }
}

export const deleteCustomerAddress = (data) => {
  return async dispatch => {
    dispatch(deleteCustomerAddressRequest())
    try {
      await del(`/customer/address/${data.id}`)
      dispatch(deleteCustomerAddressSuccess(data))
    }
    catch (err) {
      dispatch(deleteCustomerAddressFailure(err))
    }
    finally {
      dispatch(deleteCustomerAddressIdle())
    }
  }
}

export const SET_DEFAULT_DELIVERY_ADDRESS_REQUEST = "SET_DEFAULT_DELIVERY_ADDRESS_REQUEST"
export const SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS = "SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS"
export const SET_DEFAULT_DELIVERY_ADDRESS_FAILURE = "SET_DEFAULT_DELIVERY_ADDRESS_FAILURE"
export const SET_DEFAULT_DELIVERY_ADDRESS_IDLE = "SET_DEFAULT_DELIVERY_ADDRESS_IDLE"

const setDefaultDeliveryAddressRequest = () => {
  return {
    type: SET_DEFAULT_DELIVERY_ADDRESS_REQUEST
  }
}

const setDefaultDeliveryAddressSuccess = (address) => {
  return {
    type: SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS,
    payload: {
      address
    }
  }
}

const setDefaultDeliveryAddressFailure = (error) => {
  return {
    type: SET_DEFAULT_DELIVERY_ADDRESS_FAILURE,
    payload: {
      error
    }
  }
}

const setDefaultDeliveryAddressIdle = () => {
  return {
    type: SET_DEFAULT_DELIVERY_ADDRESS_IDLE
  }
}

export const setDefaultDeliveryAddress = (data, customerID) => {
  return async dispatch => {
    dispatch(setDefaultDeliveryAddressRequest())
    try {
      const response = await patch(`/customer/${customerID}/fav_address`, data)
      dispatch(setDefaultDeliveryAddressSuccess(data))
    }
    catch (err) {
      dispatch(setDefaultDeliveryAddressFailure(err))
    }
    finally {
      dispatch(setDefaultDeliveryAddressIdle())
    }
  }
}

export const SET_DEFAULT_BILLING_ADDRESS_REQUEST = "SET_DEFAULT_BILLING_ADDRESS_REQUEST"
export const SET_DEFAULT_BILLING_ADDRESS_SUCCESS = "SET_DEFAULT_BILLING_ADDRESS_SUCCESS"
export const SET_DEFAULT_BILLING_ADDRESS_FAILURE = "SET_DEFAULT_BILLING_ADDRESS_FAILURE"
export const SET_DEFAULT_BILLING_ADDRESS_IDLE = "SET_DEFAULT_BILLING_ADDRESS_IDLE"

const setDefaultBillingAddressRequest = () => {
  return {
    type: SET_DEFAULT_BILLING_ADDRESS_REQUEST
  }
}

const setDefaultBillingAddressSuccess = (address) => {
  return {
    type: SET_DEFAULT_BILLING_ADDRESS_SUCCESS,
    payload: {
      address
    }
  }
}

const setDefaultBillingAddressFailure = (error) => {
  return {
    type: SET_DEFAULT_BILLING_ADDRESS_FAILURE,
    payload: {
      error
    }
  }
}

const setDefaultBillingAddressIdle = () => {
  return {
    type: SET_DEFAULT_BILLING_ADDRESS_IDLE
  }
}

export const setDefaultBillingAddress = (data, customerID) => {
  return async dispatch => {
    dispatch(setDefaultBillingAddressRequest())
    try {
      const response = await patch(`/customer/${customerID}/fav_address`, data)
      dispatch(setDefaultBillingAddressSuccess(data))
    }
    catch (err) {
      dispatch(setDefaultBillingAddressFailure(err))
    }
    finally {
      dispatch(setDefaultBillingAddressIdle())
    }
  }
}
