import produce from "immer"
import moment from 'moment'

import {
  LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_REQUEST,
  LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_SUCCESS,
  LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_FAILURE,
  LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_IDLE,
  UPDATE_PURCHASE_ORDER_STATUS_REQUEST,
  UPDATE_PURCHASE_ORDER_STATUS_SUCCESS,
  UPDATE_PURCHASE_ORDER_STATUS_FAILURE,
  UPDATE_PURCHASE_ORDER_STATUS_IDLE,
  LOCK_STOCK_PURHCASE_ORDER_REQUEST,
  LOCK_STOCK_PURHCASE_ORDER_SUCCESS,
  LOCK_STOCK_PURHCASE_ORDER_FAILURE,
  LOCK_STOCK_PURHCASE_ORDER_IDLE,
  UPDATE_MESSENGER_PURHASE_ORDER_REQUEST,
  UPDATE_MESSENGER_PURHASE_ORDER_SUCCESS,
  UPDATE_MESSENGER_PURHASE_ORDER_FAILURE,
  UPDATE_MESSENGER_PURHASE_ORDER_IDLE,
  CREATE_PURCHASE_ORDER_SOCKET_REQUEST,
  CREATE_PURCHASE_ORDER_SOCKET_SUCCESS,
  CREATE_PURCHASE_ORDER_SOCKET_IDLE,
  UPDATE_PURCHASE_ORDER_SOCKET_REQUEST,
  UPDATE_PURCHASE_ORDER_SOCKET_SUCCESS,
  UPDATE_PURCHASE_ORDER_SOCKET_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

export const ALL = 'all'
export const PENDING = 'pending'
export const ASSEMBLY = "assembly"
export const FLOWER = "flower"
export const PREPARE = 'prepare'
export const DELIVERY = 'delivery'
export const DONE = 'done'
export const FUTURE = 'future'

const initialState = {
  listPurchaseOrder: [],
  loadListPurchaseOrderFilterRange : {
    fetchStatus: FETCH_STATUS_IDLE,
    filterType: undefined,
    error: undefined
  },
  updatePurchaseOrderStatus: {
    fetchStatus: FETCH_STATUS_IDLE,
    purchaseOrderData: undefined
  },
  lockStockPurchaseOrder: {
    fetchStatus: FETCH_STATUS_IDLE,
    purchaseOrderData: undefined,
  },
  updateMessengerPurchaseOrder: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  createPurchaseOrderSocket: {
    fetchStatus: FETCH_STATUS_IDLE,
    purchaseOrderData: undefined
  },
  updatePurchaseOrderSocket: {
    fetchStatus: FETCH_STATUS_IDLE,
    purchaseOrderData: undefined
  }
}

const prepareListPurchaseOrder = (listPurchaseOrder) => {
  const ascPreparedList = listPurchaseOrder.map(orderData => {
    return Object.assign({}, orderData, {
      prepare_date_txt: moment(orderData.prepare_date).format("DD/MM/YYYY HH:mm"),
      delivery_date_txt: moment(orderData.delivery_date).format("DD/MM/YYYY HH:mm"),
      order_date_txt: moment(orderData.order_date).format("DD/MM/YYYY HH:mm"),
      tel_txt: orderData.customer_address?.tel
    })
  }).sort((a, b) => moment(a.delivery_date).toDate() - moment(b.delivery_date).toDate())
  const updatedStatusList = ascPreparedList.filter(orderData => orderData.is_updated)
  const idleStatusList = ascPreparedList.filter(orderData => !orderData.is_updated)
  return [...updatedStatusList, ...idleStatusList]
}

const preparingDashboardPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_REQUEST: {
      return produce(state, draft => {
        draft.loadListPurchaseOrderFilterRange.fetchStatus = FETCH_STATUS_REQUEST
        draft.loadListPurchaseOrderFilterRange.filterType = action.payload.filterType
        draft.listPurchaseOrder = []
      })
    }
    case LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_SUCCESS: {
      return produce(state, draft => {
        draft.loadListPurchaseOrderFilterRange.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listPurchaseOrder = prepareListPurchaseOrder(action.payload.listPurchaseOrder)
      })
    }
    case LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_FAILURE: {
      return produce(state, draft => {
        draft.loadListPurchaseOrderFilterRange.fetchStatus = FETCH_STATUS_FAILURE
        draft.loadListPurchaseOrderFilterRange.error = action.payload.error
      })
    }
    case LOAD_LIST_PURCHASE_OREDER_FILTER_RANGE_IDLE: {
      return produce(state, draft => {
        draft.loadListPurchaseOrderFilterRange.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_PURCHASE_ORDER_STATUS_REQUEST: {
      return produce(state, draft => {
        draft.updatePurchaseOrderStatus.fetchStatus = FETCH_STATUS_REQUEST
        draft.updatePurchaseOrderStatus.purchaseOrderData = undefined
      })
    }
    case UPDATE_PURCHASE_ORDER_STATUS_SUCCESS: {
      return produce(state, draft => {
        draft.updatePurchaseOrderStatus.fetchStatus = FETCH_STATUS_SUCCESS
        draft.updatePurchaseOrderStatus.purchaseOrderData = action.payload.purchaseOrderData
        draft.listPurchaseOrder = draft.listPurchaseOrder.filter(data => data.id !== action.payload.purchaseOrderData.id)
      })
    }
    case UPDATE_PURCHASE_ORDER_STATUS_FAILURE: {
      return produce(state, draft => {
        draft.updatePurchaseOrderStatus.fetchStatus = FETCH_STATUS_FAILURE
        draft.updatePurchaseOrderStatus.error = action.payload.error
      })
    }
    case UPDATE_PURCHASE_ORDER_STATUS_IDLE: {
      return produce(state, draft => {
        draft.updatePurchaseOrderStatus.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOCK_STOCK_PURHCASE_ORDER_REQUEST: {
      return produce(state, draft => {
        draft.lockStockPurchaseOrder.fetchStatus = FETCH_STATUS_REQUEST
        draft.lockStockPurchaseOrder.purchaseOrderData = action.payload.purchaseOrderData
      })
    }
    case LOCK_STOCK_PURHCASE_ORDER_SUCCESS: {
      return produce(state, draft => {
        draft.lockStockPurchaseOrder.fetchStatus = FETCH_STATUS_SUCCESS
        draft.lockStockPurchaseOrder.purchaseOrderData = action.payload.purchaseOrderData
        draft.listPurchaseOrder = draft.listPurchaseOrder.filter(data => data.id !== action.payload.purchaseOrderData.id)
      })
    }
    case LOCK_STOCK_PURHCASE_ORDER_FAILURE: {
      return produce(state, draft => {
        draft.lockStockPurchaseOrder.fetchStatus = FETCH_STATUS_FAILURE
        draft.lockStockPurchaseOrder.error = action.payload.error
      })
    }
    case LOCK_STOCK_PURHCASE_ORDER_IDLE: {
      return produce(state, draft => {
        draft.lockStockPurchaseOrder.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_MESSENGER_PURHASE_ORDER_REQUEST: {
      return produce(state, draft => {
        draft.updateMessengerPurchaseOrder.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_MESSENGER_PURHASE_ORDER_SUCCESS: {
      return produce(state, draft => {
        draft.updateMessengerPurchaseOrder.fetchStatus = FETCH_STATUS_SUCCESS
        const selectedPurchaseOrder = draft.listPurchaseOrder.find(orderData => orderData.id === action.payload.purchaseOrderData.id)
        selectedPurchaseOrder.messenger = action.payload.messenger
      })
    }
    case UPDATE_MESSENGER_PURHASE_ORDER_FAILURE: {
      return produce(state, draft => {
        draft.updateMessengerPurchaseOrder.fetchStatus = FETCH_STATUS_FAILURE
        draft.updateMessengerPurchaseOrder.error = action.payload.error
      })
    }
    case UPDATE_MESSENGER_PURHASE_ORDER_IDLE: {
      return produce(state, draft => {
        draft.updateMessengerPurchaseOrder.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case CREATE_PURCHASE_ORDER_SOCKET_REQUEST: {
      return produce(state, draft => {
        draft.createPurchaseOrderSocket.fetchStatus = FETCH_STATUS_REQUEST
        draft.createPurchaseOrderSocket.purchaseOrderData = undefined
      })
    }
    case CREATE_PURCHASE_ORDER_SOCKET_SUCCESS: {
      let listPurchaseOrder = state.listPurchaseOrder
      const { filterType } = state.loadListPurchaseOrderFilterRange
      if(filterType === ALL || filterType === PENDING){
        listPurchaseOrder = [action.payload.purchaseOrderData, ...state.listPurchaseOrder]
      }
      return produce(state, draft => {
        draft.createPurchaseOrderSocket.fetchStatus = FETCH_STATUS_SUCCESS
        draft.createPurchaseOrderSocket.purchaseOrderData = action.payload.purchaseOrderData
        draft.listPurchaseOrder = prepareListPurchaseOrder(listPurchaseOrder)
      })
    }
    case CREATE_PURCHASE_ORDER_SOCKET_IDLE: {
      return produce(state, draft => {
        draft.createPurchaseOrderSocket.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_PURCHASE_ORDER_SOCKET_REQUEST: {
      return produce(state, draft => {
        draft.updatePurchaseOrderSocket.fetchStatus = FETCH_STATUS_REQUEST
        draft.updatePurchaseOrderSocket.purchaseOrderData = undefined
      })
    }
    case UPDATE_PURCHASE_ORDER_SOCKET_SUCCESS: {
      let listPurchaseOrder = state.listPurchaseOrder
      const { filterType } = state.loadListPurchaseOrderFilterRange
      if(filterType === ALL || filterType === PENDING){
        const purchaseOrderSocketInList = state.listPurchaseOrder.find(purchaseOrderData => purchaseOrderData.id === action.payload.purchaseOrderData.id)
        if(purchaseOrderSocketInList){
          listPurchaseOrder = state.listPurchaseOrder.map(purchaseOrderData => {
            if(purchaseOrderData.id === action.payload.purchaseOrderData.id){
              return action.payload.purchaseOrderData
            }
            return purchaseOrderData
          })
        }
        else {
          listPurchaseOrder = [action.payload.purchaseOrderData, ...state.listPurchaseOrder]
        }
      }
      else {
        listPurchaseOrder = state.listPurchaseOrder.filter(purchaseOrderData => purchaseOrderData.id !== action.payload.purchaseOrderData.id)
      }
      return produce(state, draft => {
        draft.updatePurchaseOrderSocket.fetchStatus = FETCH_STATUS_SUCCESS
        draft.updatePurchaseOrderSocket.purchaseOrderData = action.payload.purchaseOrderData
        draft.listPurchaseOrder = prepareListPurchaseOrder(listPurchaseOrder)
      })
    }
    case UPDATE_PURCHASE_ORDER_SOCKET_IDLE: {
      return produce(state, draft => {
        draft.updatePurchaseOrderSocket.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default preparingDashboardPage
