import produce from "immer"
import moment from 'moment'

import {
  GET_LIST_PURCHASE_ORDER_REQUEST,
  GET_LIST_PURCHASE_ORDER_SUCCESS,
  GET_LIST_PURCHASE_ORDER_FAILURE,
  GET_LIST_PURCHASE_ORDER_IDLE,
  INVOICE_PURCHASE_ORDER_REQUEST,
  INVOICE_PURCHASE_ORDER_SUCCESS,
  INVOICE_PURCHASE_ORDER_FAILURE,
  INVOICE_PURCHASE_ORDER_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  listPurchaseOrder: [],
  getListPurchaseOrder: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  invoicePurchaseOrder: {
    fetchStatus: FETCH_STATUS_IDLE,
    purchaseOrderData: undefined,
    error: undefined
  }
}

const purhcaseOrderManagementPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PURCHASE_ORDER_REQUEST: {
      return produce(state, draft => {
        draft.getListPurchaseOrder.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case GET_LIST_PURCHASE_ORDER_SUCCESS: {
      return produce(state, draft => {
        draft.getListPurchaseOrder.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listPurchaseOrder = action.payload.listPurchaseOrder.map(orderData => {
          return Object.assign({}, orderData, {
            prepare_date_txt: moment(orderData.prepare_date).format("DD/MM/YYYY HH:mm"),
            delivery_date_txt: moment(orderData.delivery_date).format("DD/MM/YYYY HH:mm"),
            order_date_txt: moment(orderData.order_date).format("DD/MM/YYYY HH:mm"),
            tel_txt: orderData.customer_address?.tel
          })
        })
        draft.listPurchaseOrder.sort((a, b) => moment(b.order_date).toDate() - moment(a.order_date).toDate())
      })
    }
    case GET_LIST_PURCHASE_ORDER_FAILURE: {
      return produce(state, draft => {
        draft.getListPurchaseOrder.fetchStatus = FETCH_STATUS_FAILURE
        draft.getListPurchaseOrder.error = action.payload.error
      })
    }
    case GET_LIST_PURCHASE_ORDER_IDLE: {
      return produce(state, draft => {
        draft.getListPurchaseOrder.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case INVOICE_PURCHASE_ORDER_REQUEST: {
      return produce(state, draft => {
        draft.invoicePurchaseOrder.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case INVOICE_PURCHASE_ORDER_SUCCESS: {
      return produce(state, draft => {
        draft.invoicePurchaseOrder.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listPurchaseOrder = state.listPurchaseOrder.map(purchaseOrderData => {
          if(action.payload.purchaseOrderData.id === purchaseOrderData.id){
            return Object.assign({}, purchaseOrderData, {
              invoice_status: false
            })
          }
          return purchaseOrderData
        })
        draft.invoicePurchaseOrder.purchaseOrderData = action.payload.purchaseOrderData
      })
    }
    case INVOICE_PURCHASE_ORDER_FAILURE: {
      return produce(state, draft => {
        draft.invoicePurchaseOrder.fetchStatus = FETCH_STATUS_FAILURE
        draft.invoicePurchaseOrder.error = action.payload.error
      })
    }
    case INVOICE_PURCHASE_ORDER_IDLE: {
      return produce(state, draft => {
        draft.invoicePurchaseOrder.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default purhcaseOrderManagementPage
