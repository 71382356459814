import { get, del } from '../../utils'

export const LOAD_ALL_USER_REQUEST = "LOAD_ALL_USER_REQUEST"
export const LOAD_ALL_USER_SUCCESS = "LOAD_ALL_USER_SUCCESS"
export const LOAD_ALL_USER_FAILURE = "LOAD_ALL_USER_FAILURE"
export const LOAD_ALL_USER_IDLE = "LOAD_ALL_USER_IDLE"

const loadAllUserRequest = () => {
  return {
    type: LOAD_ALL_USER_REQUEST
  }
}

const loadAllUserSuccess = (listUser) => {
  return {
    type: LOAD_ALL_USER_SUCCESS,
    payload: {
      listUser
    }
  }
}

const loadAllUserFailure = (error) => {
  return {
    type: LOAD_ALL_USER_FAILURE,
    payload: {
      error
    }
  }
}

const loadAllUserIdle = () => {
  return {
    type: LOAD_ALL_USER_IDLE
  }
}

export const loadAllUser = (data) => {
  return async dispatch => {
    dispatch(loadAllUserRequest())
    try {
      const response = await get(`/user/list`)      
      dispatch(loadAllUserSuccess(response))
    }
    catch (err) {
      dispatch(loadAllUserFailure(err))
    }
    finally {
      dispatch(loadAllUserIdle())
    }
  }
}

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE"
export const DELETE_USER_IDLE = "DELETE_USER_IDLE"

const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST
  }
}

const deleteUserSuccess = (userData) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: {
      userData
    }
  }
}

const deleteUserFailure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: {
      error
    }
  }
}

const deleteUserIdle = () => {
  return {
    type: DELETE_USER_IDLE
  }
}

export const deleteUser = (data) => {
  return async dispatch => {
    dispatch(deleteUserRequest())
    try {
      await del(`/user/${data.id}`)
      dispatch(deleteUserSuccess(data))
    }
    catch (err) {
      dispatch(deleteUserFailure(err))
    }
    finally {
      dispatch(deleteUserIdle())
    }
  }
}
