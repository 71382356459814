import { get, postForm, patch } from '../../utils'

export const LOAD_LIST_STOCK_DATE_REQUEST = "LOAD_LIST_STOCK_DATE_REQUEST"
export const LOAD_LIST_STOCK_DATE_SUCCESS = "LOAD_LIST_STOCK_DATE_SUCCESS"
export const LOAD_LIST_STOCK_DATE_FAILURE = "LOAD_LIST_STOCK_DATE_FAILURE"
export const LOAD_LIST_STOCK_DATE_IDLE = "LOAD_LIST_STOCK_DATE_IDLE"

const loadListStockDateRequest = () => {
  return {
    type: LOAD_LIST_STOCK_DATE_REQUEST
  }
}

const loadListStockDateSuccess = (listStockDate) => {
  return {
    type: LOAD_LIST_STOCK_DATE_SUCCESS,
    payload: {
      listStockDate
    }
  }
}

const loadListStockDateFailure = (error) => {
  return {
    type: LOAD_LIST_STOCK_DATE_FAILURE,
    payload: {
      error
    }
  }
}

const loadListStockDateIdle = () => {
  return {
    type: LOAD_LIST_STOCK_DATE_IDLE
  }
}

export const loadListStockDate = () => {
  return async dispatch => {
    dispatch(loadListStockDateRequest())
    try {
      const response = await get(`/product`)
      dispatch(loadListStockDateSuccess(response))
    }
    catch (err) {
      dispatch(loadListStockDateFailure(err))
    }
    finally {
      dispatch(loadListStockDateIdle())
    }
  }
}

export const CREATE_STOCK_LOT_DATE_REQUEST = "CREATE_STOCK_LOT_DATE_REQUEST"
export const CREATE_STOCK_LOT_DATE_SUCCESS = "CREATE_STOCK_LOT_DATE_SUCCESS"
export const CREATE_STOCK_LOT_DATE_FAILURE = "CREATE_STOCK_LOT_DATE_FAILURE"
export const CREATE_STOCK_LOT_DATE_IDLE = "CREATE_STOCK_LOT_DATE_IDLE"

const createStockLotDateRequest = () => {
  return {
    type: CREATE_STOCK_LOT_DATE_REQUEST
  }
}

const createStockLotDateSuccess = (createdStockLotDateData) => {
  return {
    type: CREATE_STOCK_LOT_DATE_SUCCESS,
    payload: {
      createdStockLotDateData
    }
  }
}

const createStockLotDateFailure = (error) => {
  return {
    type: CREATE_STOCK_LOT_DATE_FAILURE,
    payload: {
      error
    }
  }
}

const createStockLotDateIdle = () => {
  return {
    type: CREATE_STOCK_LOT_DATE_IDLE
  }
}

export const createStockLotDate = (data) => {
  return async dispatch => {
    dispatch(createStockLotDateRequest())
    try {
      const response = await postForm(`/product`, data)
      dispatch(createStockLotDateSuccess(response))
    }
    catch (err) {
      dispatch(createStockLotDateFailure(err))
    }
    finally {
      dispatch(createStockLotDateIdle())
    }
  }
}

export const UPDATE_STOCK_LOT_REQUEST = "UPDATE_STOCK_LOT_REQUEST"
export const UPDATE_STOCK_LOT_SUCCESS = "UPDATE_STOCK_LOT_SUCCESS"
export const UPDATE_STOCK_LOT_FAILURE = "UPDATE_STOCK_LOT_FAILURE"
export const UPDATE_STOCK_LOT_IDLE = "UPDATE_STOCK_LOT_IDLE"

const updateStockLotRequest = () => {
  return {
    type: UPDATE_STOCK_LOT_REQUEST
  }
}

const updateStockLotSuccess = () => {
  return {
    type: UPDATE_STOCK_LOT_SUCCESS,
  }
}

const updateStockLotFailure = (error) => {
  return {
    type: UPDATE_STOCK_LOT_FAILURE,
    payload: {
      error
    }
  }
}

const updateStockLotIdle = () => {
  return {
    type: UPDATE_STOCK_LOT_IDLE
  }
}

export const updateStockLot = (data) => {
  return async dispatch => {
    dispatch(updateStockLotRequest())
    try {
      await patch(`/product/${data.id}`, data)
      dispatch(updateStockLotSuccess())
    }
    catch (err) {
      dispatch(updateStockLotFailure(err))
    }
    finally {
      dispatch(updateStockLotIdle())
    }
  }
}

export const LOAD_LIST_STOCK_LOG_REQUEST = "LOAD_LIST_STOCK_LOG_REQUEST"
export const LOAD_LIST_STOCK_LOG_SUCCESS = "LOAD_LIST_STOCK_LOG_SUCCESS"
export const LOAD_LIST_STOCK_LOG_FAILURE = "LOAD_LIST_STOCK_LOG_FAILURE"
export const LOAD_LIST_STOCK_LOG_IDLE = "LOAD_LIST_STOCK_LOG_IDLE"

const loadListStockLogRequest = () => {
  return {
    type: LOAD_LIST_STOCK_LOG_REQUEST
  }
}

const loadListStockLogSuccess = (listStockLog) => {
  return {
    type: LOAD_LIST_STOCK_LOG_SUCCESS,
    payload: {
      listStockLog
    }
  }
}

const loadListStockLogFailure = (error) => {
  return {
    type: LOAD_LIST_STOCK_LOG_FAILURE,
    payload: {
      error
    }
  }
}

const loadListStockLogIdle = () => {
  return {
    type: LOAD_LIST_STOCK_LOG_IDLE
  }
}

export const loadListStockLog = () => {
  return async dispatch => {
    dispatch(loadListStockLogRequest())
    try {
      const response = await get(`/stock`)
      dispatch(loadListStockLogSuccess(response))
    }
    catch (err) {
      dispatch(loadListStockLogFailure(err))
    }
    finally {
      dispatch(loadListStockLogIdle())
    }
  }
}

export const CREATE_STOCK_LOG_REQUEST = "CREATE_STOCK_LOG_REQUEST"
export const CREATE_STOCK_LOG_SUCCESS = "CREATE_STOCK_LOG_SUCCESS"
export const CREATE_STOCK_LOG_FAILURE = "CREATE_STOCK_LOG_FAILURE"
export const CREATE_STOCK_LOG_IDLE = "CREATE_STOCK_LOG_IDLE"

const createStockLogRequest = () => {
  return {
    type: CREATE_STOCK_LOG_REQUEST
  }
}

const createStockLogSuccess = (createdStockLog) => {
  return {
    type: CREATE_STOCK_LOG_SUCCESS,
    payload: {
      createdStockLog
    }
  }
}

const createStockLogFailure = (error) => {
  return {
    type: CREATE_STOCK_LOG_FAILURE,
    payload: {
      error
    }
  }
}

const createStockLogIdle = () => {
  return {
    type: CREATE_STOCK_LOG_IDLE
  }
}

export const createStockLog = (data) => {
  return async dispatch => {
    dispatch(createStockLogRequest())
    try {
      const response = await postForm(`/stock`, data)
      dispatch(createStockLogSuccess(response))
    }
    catch (err) {
      dispatch(createStockLogFailure(err))
    }
    finally {
      dispatch(createStockLogIdle())
    }
  }
}
