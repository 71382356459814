import { get, post } from '../../utils'

export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGIN_IDLE = "LOGIN_IDLE"

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  }
}

const loginSuccess = ({ token }) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      token
    }
  }
}

const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: {
      error
    }
  }
}

const loginIdle = () => {
  return {
    type: LOGIN_IDLE
  }
}

export const login = (data) => {
  return async dispatch => {
    dispatch(loginRequest())
    try {
      const response = await post(`/user/login`, data)
      dispatch(loginSuccess(response))
    }
    catch (err) {      
      dispatch(loginFailure(err))
    }
    finally {
      dispatch(loginIdle())
    }
  }
}

export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"
export const LOGOUT_IDLE = "LOGOUT_IDLE"

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  }
}

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  }
}

const logoutFailure = (error) => {
  return {
    type: LOGOUT_FAILURE,
    payload: {
      error
    }
  }
}

const logoutIdle = () => {
  return {
    type: LOGOUT_IDLE
  }
}

export const logout = () => {
  return async dispatch => {
    dispatch(logoutRequest())
    try {
      dispatch(logoutSuccess())
    }
    catch (err) {
      dispatch(logoutFailure(err))
    }
    finally {
      dispatch(logoutIdle())
    }
  }
}

export const AUTO_LOGIN_REQUEST = "AUTO_LOGIN_REQUEST"
export const AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS"
export const AUTO_LOGIN_FAILURE = "AUTO_LOGIN_FAILURE"
export const AUTO_LOGIN_IDLE = "AUTO_LOGIN_IDLE"

const autoLoginRequest = () => {
  return {
    type: AUTO_LOGIN_REQUEST
  }
}

const autoLoginSuccess = (userData) => {
  return {
    type: AUTO_LOGIN_SUCCESS,
    payload: {
      userData
    }
  }
}

const autoLoginFailure = (error) => {
  return {
    type: AUTO_LOGIN_FAILURE,
    payload: {
      error
    }
  }
}

const autoLoginIdle = () => {
  return {
    type: AUTO_LOGIN_IDLE
  }
}

export const autoLogin = () => {
  return async dispatch => {
    dispatch(autoLoginRequest())
    try {
      const response = await get(`/user`)
      dispatch(autoLoginSuccess(response))
    }
    catch (err) {      
      dispatch(autoLoginFailure(err))
    }
    finally {
      dispatch(autoLoginIdle())
    }
  }
}
