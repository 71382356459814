import produce from "immer"

import {
  GET_LIST_STOCK_REQUEST,
  GET_LIST_STOCK_SUCCESS,
  GET_LIST_STOCK_FAILURE,
  GET_LIST_STOCK_IDLE,
  DELETE_STOCK_REQUEST,
  DELETE_STOCK_SUCCESS,
  DELETE_STOCK_FAILURE,
  DELETE_STOCK_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  listStock: [],
  getListStock: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  deleteStock: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const stockManagementPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_STOCK_REQUEST: {
      return produce(state, draft => {
        draft.getListStock.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case GET_LIST_STOCK_SUCCESS: {
      return produce(state, draft => {
        draft.getListStock.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listStock = action.payload.listStock.sort((a ,b) => {
          if(a.code < b.code) { return -1; }
          if(a.code > b.code) { return 1; }
          return 0;
        })
      })
    }
    case GET_LIST_STOCK_FAILURE: {
      return produce(state, draft => {
        draft.getListStock.fetchStatus = FETCH_STATUS_FAILURE
        draft.getListStock.error = action.payload.error
      })
    }
    case GET_LIST_STOCK_IDLE: {
      return produce(state, draft => {
        draft.getListStock.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_STOCK_REQUEST: {
      return produce(state, draft => {
        draft.deleteStock.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_STOCK_SUCCESS: {
      return produce(state, draft => {
        draft.deleteStock.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listStock = state.listStock.filter(data => data.id !== action.payload.stockData.id)
      })
    }
    case DELETE_STOCK_FAILURE: {
      return produce(state, draft => {
        draft.deleteStock.fetchStatus = FETCH_STATUS_FAILURE
        draft.deleteStock.error = action.payload.error
      })
    }
    case DELETE_STOCK_IDLE: {
      return produce(state, draft => {
        draft.deleteStock.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default stockManagementPage
