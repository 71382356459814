import queryString from 'query-string'
import { get, patch } from '../../utils'

export const GET_LIST_PURCHASE_ORDER_REQUEST = "GET_LIST_PURCHASE_ORDER_REQUEST"
export const GET_LIST_PURCHASE_ORDER_SUCCESS = "GET_LIST_PURCHASE_ORDER_SUCCESS"
export const GET_LIST_PURCHASE_ORDER_FAILURE = "GET_LIST_PURCHASE_ORDER_FAILURE"
export const GET_LIST_PURCHASE_ORDER_IDLE = "GET_LIST_PURCHASE_ORDER_IDLE"

const getListPurchaseOrderRequest = () => {
  return {
    type: GET_LIST_PURCHASE_ORDER_REQUEST
  }
}

const getListPurchaseOrderSuccess = (listPurchaseOrder) => {
  return {
    type: GET_LIST_PURCHASE_ORDER_SUCCESS,
    payload: {
      listPurchaseOrder
    }
  }
}

const getListPurchaseOrderFailure = (error) => {
  return {
    type: GET_LIST_PURCHASE_ORDER_FAILURE,
    payload: {
      error
    }
  }
}

const getListPurchaseOrderIdle = () => {
  return {
    type: GET_LIST_PURCHASE_ORDER_IDLE
  }
}

export const getListPurchaseOrder = ({ start_time, end_time }) => {
  return async dispatch => {
    const query = queryString.stringify({
      start_time, end_time
    })
    dispatch(getListPurchaseOrderRequest())
    try {
      const response = await get(`/order?${query}`)
      dispatch(getListPurchaseOrderSuccess(response))
    }
    catch (err) {
      dispatch(getListPurchaseOrderFailure(err))
    }
    finally {
      dispatch(getListPurchaseOrderIdle())
    }
  }
}

export const INVOICE_PURCHASE_ORDER_REQUEST = "INVOICE_PURCHASE_ORDER_REQUEST"
export const INVOICE_PURCHASE_ORDER_SUCCESS = "INVOICE_PURCHASE_ORDER_SUCCESS"
export const INVOICE_PURCHASE_ORDER_FAILURE = "INVOICE_PURCHASE_ORDER_FAILURE"
export const INVOICE_PURCHASE_ORDER_IDLE = "INVOICE_PURCHASE_ORDER_IDLE"

const invoicePurchaseOrderRequest = () => {
  return {
    type: INVOICE_PURCHASE_ORDER_REQUEST
  }
}

const invoicePurchaseOrderSuccess = (purchaseOrderData) => {
  return {
    type: INVOICE_PURCHASE_ORDER_SUCCESS,
    payload: {
      purchaseOrderData,
    }
  }
}

const invoicePurchaseOrderFailure = (error) => {
  return {
    type: INVOICE_PURCHASE_ORDER_FAILURE,
    payload: {
      error
    }
  }
}

const invoicePurchaseOrderIdle = () => {
  return {
    type: INVOICE_PURCHASE_ORDER_IDLE
  }
}

export const invoicePurchaseOrder = (purchaseOrder) => {
  return async dispatch => {
    dispatch(invoicePurchaseOrderRequest())
    try {
      await patch(`/order/${purchaseOrder.id}/void`)
      dispatch(invoicePurchaseOrderSuccess(purchaseOrder))
    }
    catch (err) {
      dispatch(invoicePurchaseOrderFailure(err))
    }
    finally {
      dispatch(invoicePurchaseOrderIdle())
    }
  }
}
