import queryString from 'query-string'
import { get, del } from '../../utils'

export const GET_LIST_CUSTOMER_REQUEST = "GET_LIST_CUSTOMER_REQUEST"
export const GET_LIST_CUSTOMER_SUCCESS = "GET_LIST_CUSTOMER_SUCCESS"
export const GET_LIST_CUSTOMER_FAILURE = "GET_LIST_CUSTOMER_FAILURE"
export const GET_LIST_CUSTOMER_IDLE = "GET_LIST_CUSTOMER_IDLE"

const getListCustomerRequest = () => {
  return {
    type: GET_LIST_CUSTOMER_REQUEST
  }
}

const getListCustomerSuccess = ({ total_pages, current_page, total_items, items: listCustomer }) => {
  return {
    type: GET_LIST_CUSTOMER_SUCCESS,
    payload: {
      total_pages, current_page, total_items, listCustomer
    }
  }
}

const getListCustomerFailure = (error) => {
  return {
    type: GET_LIST_CUSTOMER_FAILURE,
    payload: {
      error
    }
  }
}

const getListCustomerIdle = () => {
  return {
    type: GET_LIST_CUSTOMER_IDLE
  }
}

export const getListCustomer = ({ page, key, search }) => {
  return async dispatch => {
    const query = queryString.stringify({
      key,
      search
    })
    dispatch(getListCustomerRequest())
    try {
      const response = await get(`/customer/${page}?${query}`)
      dispatch(getListCustomerSuccess(response))
    }
    catch (err) {
      dispatch(getListCustomerFailure(err))
    }
    finally {
      dispatch(getListCustomerIdle())
    }
  }
}

export const GET_ALL_LIST_CUSTOMER_REQUEST = "GET_ALL_LIST_CUSTOMER_REQUEST"
export const GET_ALL_LIST_CUSTOMER_SUCCESS = "GET_ALL_LIST_CUSTOMER_SUCCESS"
export const GET_ALL_LIST_CUSTOMER_FAILURE = "GET_ALL_LIST_CUSTOMER_FAILURE"
export const GET_ALL_LIST_CUSTOMER_IDLE = "GET_ALL_LIST_CUSTOMER_IDLE"

const getAllListCustomerRequest = () => {
  return {
    type: GET_ALL_LIST_CUSTOMER_REQUEST
  }
}

const getAllListCustomerSuccess = (listCustomer) => {
  return {
    type: GET_ALL_LIST_CUSTOMER_SUCCESS,
    payload: {
      listCustomer
    }
  }
}

const getAllListCustomerFailure = (error) => {
  return {
    type: GET_ALL_LIST_CUSTOMER_FAILURE,
    payload: {
      error
    }
  }
}

const getAllListCustomerIdle = () => {
  return {
    type: GET_ALL_LIST_CUSTOMER_IDLE
  }
}

export const getAllListCustomer = () => {
  return async dispatch => {
    dispatch(getAllListCustomerRequest())
    try {
      const response = await get(`/customer/list`)
      dispatch(getAllListCustomerSuccess(response))
    }
    catch (err) {
      dispatch(getAllListCustomerFailure(err))
    }
    finally {
      dispatch(getAllListCustomerIdle())
    }
  }
}

export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE"
export const DELETE_CUSTOMER_IDLE = "DELETE_CUSTOMER_IDLE"

const deleteCustomerRequest = () => {
  return {
    type: DELETE_CUSTOMER_REQUEST
  }
}

const deleteCustomerSuccess = (customerData) => {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: {
      customerData
    }
  }
}

const deleteCustomerFailure = (error) => {
  return {
    type: DELETE_CUSTOMER_FAILURE,
    payload: {
      error
    }
  }
}

const deleteCustomerIdle = () => {
  return {
    type: DELETE_CUSTOMER_IDLE
  }
}

export const deleteCustomer = (data) => {
  return async dispatch => {
    dispatch(deleteCustomerRequest())
    try {
      await del(`/customer/${data.id}`)
      dispatch(deleteCustomerSuccess(data))
    }
    catch (err) {
      dispatch(deleteCustomerFailure(err))
    }
    finally {
      dispatch(deleteCustomerIdle())
    }
  }
}
