import produce from "immer"

import {
  CREATE_PURCHASE_ORDER_REQUEST,
  CREATE_PURCHASE_ORDER_SUCCESS,
  CREATE_PURCHASE_ORDER_FAILURE,
  CREATE_PURCHASE_ORDER_IDLE,
  UPDATE_PURCHASE_ORDER_REQUEST,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_FAILURE,
  UPDATE_PURCHASE_ORDER_IDLE,
  GET_PURCHASE_ORDER_REQUEST,
  GET_PURCHASE_ORDER_SUCCESS,
  GET_PURCHASE_ORDER_FAILURE,
  GET_PURCHASE_ORDER_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  createPurchaseOrder: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  updatePurchaseOrder: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  getPurchaseOrder: {
    fetchStatus: FETCH_STATUS_IDLE,
    purchaseOrderData: undefined,
    error: undefined
  }
}

const purcaseOrderFormPage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PURCHASE_ORDER_REQUEST: {
      return produce(state, draft => {
        draft.createPurchaseOrder.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_PURCHASE_ORDER_SUCCESS: {
      return produce(state, draft => {
        draft.createPurchaseOrder.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_PURCHASE_ORDER_FAILURE: {
      return produce(state, draft => {
        draft.createPurchaseOrder.fetchStatus = FETCH_STATUS_FAILURE
        draft.createPurchaseOrder.error = action.payload.error
      })
    }
    case CREATE_PURCHASE_ORDER_IDLE: {
      return produce(state, draft => {
        draft.createPurchaseOrder.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_PURCHASE_ORDER_REQUEST: {
      return produce(state, draft => {
        draft.updatePurchaseOrder.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_PURCHASE_ORDER_SUCCESS: {
      return produce(state, draft => {
        draft.updatePurchaseOrder.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case UPDATE_PURCHASE_ORDER_FAILURE: {
      return produce(state, draft => {
        draft.updatePurchaseOrder.fetchStatus = FETCH_STATUS_FAILURE
        draft.updatePurchaseOrder.error = action.payload.error
      })
    }
    case UPDATE_PURCHASE_ORDER_IDLE: {
      return produce(state, draft => {
        draft.updatePurchaseOrder.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case GET_PURCHASE_ORDER_REQUEST: {
      return produce(state, draft => {
        draft.getPurchaseOrder.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case GET_PURCHASE_ORDER_SUCCESS: {
      return produce(state, draft => {
        draft.getPurchaseOrder.fetchStatus = FETCH_STATUS_SUCCESS
        draft.getPurchaseOrder.purchaseOrderData = action.payload.purchaseOrderData[0]
      })
    }
    case GET_PURCHASE_ORDER_FAILURE: {
      return produce(state, draft => {
        draft.getPurchaseOrder.fetchStatus = FETCH_STATUS_FAILURE
        draft.getPurchaseOrder.error = action.payload.error
      })
    }
    case GET_PURCHASE_ORDER_IDLE: {
      return produce(state, draft => {
        draft.getPurchaseOrder.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default purcaseOrderFormPage
