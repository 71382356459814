import { post, patch } from '../../utils'

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE"
export const CREATE_USER_IDLE = "CREATE_USER_IDLE"

const createUserRequest = () => {
  return {
    type: CREATE_USER_REQUEST
  }
}

const createUserSuccess = () => {
  return {
    type: CREATE_USER_SUCCESS
  }
}

const createUserFailure = (error) => {
  return {
    type: CREATE_USER_FAILURE,
    payload: {
      error
    }
  }
}

const createUserIdle = () => {
  return {
    type: CREATE_USER_IDLE
  }
}

export const createUser = (data) => {
  return async dispatch => {
    dispatch(createUserRequest())
    try {
      const response = await post(`/user`, data)
      dispatch(createUserSuccess(response))
    }
    catch (err) {
      dispatch(createUserFailure(err))
    }
    finally {
      dispatch(createUserIdle())
    }
  }
}

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"
export const UPDATE_USER_IDLE = "UPDATE_USER_IDLE"

const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST
  }
}

const updateUserSuccess = () => {
  return {
    type: UPDATE_USER_SUCCESS
  }
}

const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: {
      error
    }
  }
}

const updateUserIdle = () => {
  return {
    type: UPDATE_USER_IDLE
  }
}

export const updateUser = (data) => {
  return async dispatch => {
    dispatch(updateUserRequest())
    try {
      const response = await patch(`/user/${data.id}/info`, data)
      dispatch(updateUserSuccess(response))
    }
    catch (err) {
      dispatch(updateUserFailure(err))
    }
    finally {
      dispatch(updateUserIdle())
    }
  }
}
