import produce from "immer"

import {
  GET_LIST_CUSTOMER_REQUEST,
  GET_LIST_CUSTOMER_SUCCESS,
  GET_LIST_CUSTOMER_FAILURE,
  GET_LIST_CUSTOMER_IDLE,
  GET_ALL_LIST_CUSTOMER_REQUEST,
  GET_ALL_LIST_CUSTOMER_SUCCESS,
  GET_ALL_LIST_CUSTOMER_FAILURE,
  GET_ALL_LIST_CUSTOMER_IDLE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  listCustomer: [],
  paginationData: {
    total_pages: 1,
    current_page: 1,
    total_items: 0
  },
  getListCustomer: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  getAllListCustomer: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  deleteCustomer: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const customerManagementPage = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_CUSTOMER_REQUEST: {
      return produce(state, draft => {
        draft.getListCustomer.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case GET_LIST_CUSTOMER_SUCCESS: {
      return produce(state, draft => {
        const { total_pages, current_page, total_items, listCustomer } = action.payload
        const preparedlistCustomer = listCustomer.map(customerData => {
          return Object.assign({}, customerData, {
            customer_addresses: customerData.customer_addresses.map(addressData => {
              const preparedAddress = Object.assign({}, addressData, {
                amphoe: addressData.sub_district,
                zipcode: addressData.postcode
              })
              if(customerData.customer_fav_addresses.length > 0){
                return Object.assign({}, preparedAddress, {
                  defaultDelivery: customerData.customer_fav_addresses[0].delivery === preparedAddress.id,
                  defaultBilling: customerData.customer_fav_addresses[0].billing === preparedAddress.id
                })
              }
              return preparedAddress
            }).sort((a, b) => {
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
            })
          })
        }).sort((a, b) => {
          if(a.firstname < b.firstname) { return -1; }
          if(a.firstname > b.firstname) { return 1; }
          return 0;
        })
        draft.getListCustomer.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listCustomer = preparedlistCustomer
        draft.paginationData = {
          total_pages,
          current_page,
          total_items
        }
      })
    }
    case GET_LIST_CUSTOMER_FAILURE: {
      return produce(state, draft => {
        draft.getListCustomer.fetchStatus = FETCH_STATUS_FAILURE
        draft.getListCustomer.error = action.payload.error
      })
    }
    case GET_LIST_CUSTOMER_IDLE: {
      return produce(state, draft => {
        draft.getListCustomer.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_CUSTOMER_REQUEST: {
      return produce(state, draft => {
        draft.deleteCustomer.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_CUSTOMER_SUCCESS: {
      return produce(state, draft => {
        draft.deleteCustomer.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listCustomer = state.listCustomer.filter(data => data.id !== action.payload.customerData.id)
      })
    }
    case DELETE_CUSTOMER_FAILURE: {
      return produce(state, draft => {
        draft.deleteCustomer.fetchStatus = FETCH_STATUS_FAILURE
        draft.deleteCustomer.error = action.payload.error
      })
    }
    case DELETE_CUSTOMER_IDLE: {
      return produce(state, draft => {
        draft.deleteCustomer.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case GET_ALL_LIST_CUSTOMER_REQUEST: {
      return produce(state, draft => {
        draft.getAllListCustomer.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case GET_ALL_LIST_CUSTOMER_SUCCESS: {
      return produce(state, draft => {
        const preparedlistCustomer = action.payload.listCustomer.map(customerData => {
          return Object.assign({}, customerData, {
            customer_addresses: customerData.customer_addresses.map(addressData => {
              const preparedAddress = Object.assign({}, addressData, {
                amphoe: addressData.sub_district,
                zipcode: addressData.postcode
              })
              if(customerData.customer_fav_addresses.length > 0){
                return Object.assign({}, preparedAddress, {
                  defaultDelivery: customerData.customer_fav_addresses[0].delivery === preparedAddress.id,
                  defaultBilling: customerData.customer_fav_addresses[0].billing === preparedAddress.id
                })
              }
              return preparedAddress
            }).sort((a, b) => {
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
            })
          })
        }).sort((a, b) => {
          if(a.firstname < b.firstname) { return -1; }
          if(a.firstname > b.firstname) { return 1; }
          return 0;
        })
        draft.listCustomer = preparedlistCustomer
        draft.getAllListCustomer.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case GET_ALL_LIST_CUSTOMER_FAILURE: {
      return produce(state, draft => {
        draft.getAllListCustomer.fetchStatus = FETCH_STATUS_FAILURE
        draft.getAllListCustomer.error = action.payload.error
      })
    }
    case GET_ALL_LIST_CUSTOMER_IDLE: {
      return produce(state, draft => {
        draft.getAllListCustomer.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default customerManagementPage
