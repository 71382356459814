import io from 'socket.io-client';
import { get } from '../../utils'
import { createPurchaseOrderSocket, updatePurchaseOrderSocket } from '../PreparingDashboardPage/actions'
const ORDER_CHANNEL = "order_channel"

export const SET_MESSAGE = "SET_MESSAGE"

export const setMessage = (message) => {
  return async dispatch => {
    dispatch({
      type: SET_MESSAGE,
      payload: {
        message
      }
    })
  }
}

export const connectSocket = () => {
  return async dispatch => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, { transports: ['websocket', "polling"] });
    socket.on('connect', function(){
      socket.emit(ORDER_CHANNEL);
    });
    socket.on('created_order', function(data){
      const [purchaseOrderData] = data
      dispatch(createPurchaseOrderSocket(purchaseOrderData))
    });
    socket.on('updated_order', function(data){
      const [purchaseOrderData] = data
      dispatch(updatePurchaseOrderSocket(purchaseOrderData))
    });
  }
}
