import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

import browserHistory from './history'

import Application from '../containers/Application/Loadable'
import LoginPage from '../containers/LoginPage/Loadable'
import PDFLayoutPgae from "../containers/PDFLayoutPage/Loadable"

const handleRouteChanged = (arg) => {
  window.scrollTo(0, 0);
}

browserHistory.listen(arg => {
  handleRouteChanged(arg)
})

export default (
  <Router history={browserHistory}>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/home/dashboard"/>} />
      <Route path='/home' component={Application} />
      <Route path='/login' component={LoginPage} />
      <Route path='/print/order/:id' component={PDFLayoutPgae} />
      <Route render={() => <div>not found</div>} />
    </Switch>
  </Router>
)
