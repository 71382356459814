import produce from "immer"

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_IDLE,
  AUTO_LOGIN_REQUEST,
  AUTO_LOGIN_SUCCESS,
  AUTO_LOGIN_FAILURE,
  AUTO_LOGIN_IDLE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_IDLE
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE,
  storage
} from '../../utils'

const initialState = {
  currentUser: undefined,
  login: {
    fetchStatus: FETCH_STATUS_IDLE
  }
}

const loginPage = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOGIN_SUCCESS: {
      storage.set("user_token", action.payload.token)
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case LOGIN_FAILURE: {
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_FAILURE
        draft.login.error = action.payload.error
      })
    }
    case LOGIN_IDLE: {
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case AUTO_LOGIN_REQUEST: {
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case AUTO_LOGIN_SUCCESS: {
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_SUCCESS
        draft.currentUser = action.payload.userData
      })
    }
    case AUTO_LOGIN_FAILURE: {
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_FAILURE
        draft.login.error = action.payload.error
      })
    }
    case AUTO_LOGIN_IDLE: {
      return produce(state, draft => {
        draft.login.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOGOUT_SUCCESS: {
      storage.remove("user_token")
      return produce(state, draft => {
        draft.currentUser = undefined
      })
    }
    default: {
      return state
    }
  }
}

export default loginPage
