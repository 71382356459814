import produce from "immer"

import {
  LOAD_ALL_USER_REQUEST,
  LOAD_ALL_USER_SUCCESS,
  LOAD_ALL_USER_FAILURE,
  LOAD_ALL_USER_IDLE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  listUser: [],
  loadAllUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  deleteUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    userData: undefined,
    error: undefined
  }
}

const userManagementPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_USER_REQUEST: {
      return produce(state, draft => {
        draft.loadAllUser.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_ALL_USER_SUCCESS: {
      return produce(state, draft => {
        draft.loadAllUser.fetchStatus = FETCH_STATUS_SUCCESS
        draft.listUser = action.payload.listUser.sort((a, b) => {
          if(a.firstname < b.firstname) { return -1; }
          if(a.firstname > b.firstname) { return 1; }
          return 0;
        })
      })
    }
    case LOAD_ALL_USER_FAILURE: {
      return produce(state, draft => {
        draft.loadAllUser.fetchStatus = FETCH_STATUS_FAILURE
      })
    }
    case LOAD_ALL_USER_IDLE: {
      return produce(state, draft => {
        draft.loadAllUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_USER_REQUEST: {
      return produce(state, draft => {
        draft.deleteUser.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_USER_SUCCESS: {
      return produce(state, draft => {
        draft.deleteUser.fetchStatus = FETCH_STATUS_SUCCESS
        draft.deleteUser.userData = action.payload.userData
      })
    }
    case DELETE_USER_FAILURE: {
      return produce(state, draft => {
        draft.deleteUser.fetchStatus = FETCH_STATUS_FAILURE
      })
    }
    case DELETE_USER_IDLE: {
      return produce(state, draft => {
        draft.deleteUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default userManagementPage
