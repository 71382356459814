import queryString from 'query-string'

import { get } from '../../utils'

export const GET_DASHBOARD_GRAPH_DATA_REQUEST = "GET_DASHBOARD_GRAPH_DATA_REQUEST"
export const GET_DASHBOARD_GRAPH_DATA_SUCCESS = "GET_DASHBOARD_GRAPH_DATA_SUCCESS"
export const GET_DASHBOARD_GRAPH_DATA_FAILURE = "GET_DASHBOARD_GRAPH_DATA_FAILURE"
export const GET_DASHBOARD_GRAPH_DATA_IDLE = "GET_DASHBOARD_GRAPH_DATA_IDLE"

const getDashboardGraphDataRequest = () => {
  return {
    type: GET_DASHBOARD_GRAPH_DATA_REQUEST
  }
}

const getDashboardGraphDataSuccess = ({ saleGraphData, saleRankData, type }) => {
  return {
    type: GET_DASHBOARD_GRAPH_DATA_SUCCESS,
    payload: {
      saleGraphData, saleRankData, type
    }
  }
}

const getDashboardGraphDataFailure = (error) => {
  return {
    type: GET_DASHBOARD_GRAPH_DATA_FAILURE,
    payload: {
      error
    }
  }
}

const getDashboardGraphDataIdle = () => {
  return {
    type: GET_DASHBOARD_GRAPH_DATA_IDLE
  }
}

export const getDashboardGraphData = ({ start_time, end_time, type }) => {
  return async dispatch => {
    const query = queryString.stringify({
      start_time, end_time, type
    })
    dispatch(getDashboardGraphDataRequest())
    try {
      const [saleGraphData, saleRankData] = await Promise.all([
        get(`/dashboard/order?${query}`),
        get(`/dashboard/ranking?${query}`)
      ])
      dispatch(getDashboardGraphDataSuccess({ saleGraphData, saleRankData, type }))
    }
    catch (err) {
      dispatch(getDashboardGraphDataFailure(err))
    }
    finally {
      dispatch(getDashboardGraphDataIdle())
    }
  }
}
