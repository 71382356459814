import produce from "immer"

import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_IDLE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  createUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  updateUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const userFormPage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST: {
      return produce(state, draft => {
        draft.createUser.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_USER_SUCCESS: {
      return produce(state, draft => {
        draft.createUser.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_USER_FAILURE: {
      return produce(state, draft => {
        draft.createUser.fetchStatus = FETCH_STATUS_FAILURE
        draft.createUser.error = action.payload.error
      })
    }
    case CREATE_USER_IDLE: {
      return produce(state, draft => {
        draft.createUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_USER_REQUEST: {
      return produce(state, draft => {
        draft.updateUser.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_USER_SUCCESS: {
      return produce(state, draft => {
        draft.updateUser.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case UPDATE_USER_FAILURE: {
      return produce(state, draft => {
        draft.updateUser.fetchStatus = FETCH_STATUS_FAILURE
        draft.updateUser.error = action.payload.error
      })
    }
    case UPDATE_USER_IDLE: {
      return produce(state, draft => {
        draft.updateUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default userFormPage
