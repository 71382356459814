import { combineReducers } from 'redux'

import application from '../containers/Application/reducer'
import dashboardPage from '../containers/DashboardPage/reducer'
import loginPage from '../containers/LoginPage/reducer'
import stockManagementPage from '../containers/StockManagementPage/reducer'
import stockCostPage from '../containers/StockManagementCostPage/reducer'
import stockFormPage from '../containers/StockManagementFormPage/reducer'
import productManagementPage from '../containers/ProductManagementPage/reducer'
import productFormPage from '../containers/ProductManagementFormPage/reducer'
import userFormPage from '../containers/UserManagementFormPage/reducer'
import customerManagementPage from '../containers/CustomerManagementPage/reducer'
import customerFormPage from '../containers/CustomerManagementFormPage/reducer'
import userManagementPage from '../containers/UserManagementPage/reducer'
import purchaseOrderManagementPage from '../containers/PurchaseOrderManagementPage/reducer'
import purchaseOrderFormPage from '../containers/PurchaseOrderManagementFormPage/reducer'
import preparingDashboardPage from '../containers/PreparingDashboardPage/reducer'

const rootReducer = combineReducers({
  application,
  loginPage,
  stockCostPage,
  stockManagementPage,
  stockFormPage,
  productManagementPage,
  productFormPage,
  userFormPage,
  customerManagementPage,
  customerFormPage,
  userManagementPage,
  purchaseOrderManagementPage,
  purchaseOrderFormPage,
  preparingDashboardPage,
  dashboardPage
})

export default rootReducer;
