import produce from "immer"

import {
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_IDLE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_IDLE,
  CREATE_CUSTOMER_ADDRESS_REQUEST,
  CREATE_CUSTOMER_ADDRESS_SUCCESS,
  CREATE_CUSTOMER_ADDRESS_FAILURE,
  CREATE_CUSTOMER_ADDRESS_IDLE,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAILURE,
  UPDATE_CUSTOMER_ADDRESS_IDLE,
  DELETE_CUSTOMER_ADDRESS_REQUEST,
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  DELETE_CUSTOMER_ADDRESS_FAILURE,
  DELETE_CUSTOMER_ADDRESS_IDLE,
  SET_DEFAULT_DELIVERY_ADDRESS_REQUEST,
  SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS,
  SET_DEFAULT_DELIVERY_ADDRESS_FAILURE,
  SET_DEFAULT_DELIVERY_ADDRESS_IDLE,
  SET_DEFAULT_BILLING_ADDRESS_REQUEST,
  SET_DEFAULT_BILLING_ADDRESS_SUCCESS,
  SET_DEFAULT_BILLING_ADDRESS_FAILURE,
  SET_DEFAULT_BILLING_ADDRESS_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  createCustomer: {
    fetchStatus: FETCH_STATUS_IDLE,
    customerData: undefined,
    error: undefined
  },
  updateCustomer: {
    fetchStatus: FETCH_STATUS_IDLE,
    customerData: undefined,
    error: undefined
  },
  createCustomerAddress: {
    fetchStatus: FETCH_STATUS_IDLE,
    address: undefined,
    error: undefined
  },
  updateCustomerAddress: {
    fetchStatus: FETCH_STATUS_IDLE,
    address: undefined,
    error: undefined
  },
  deleteCustomerAddress: {
    fetchStatus: FETCH_STATUS_IDLE,
    address: undefined,
    error: undefined
  },
  setDefaultDeliveryAddress: {
    fetchStatus: FETCH_STATUS_IDLE,
    address: undefined,
    error: undefined
  },
  setDefaultBillingAddress: {
    fetchStatus: FETCH_STATUS_IDLE,
    address: undefined,
    error: undefined
  }
}

const customerFormPage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST: {
      return produce(state, draft => {
        draft.createCustomer.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_CUSTOMER_SUCCESS: {
      return produce(state, draft => {
        draft.createCustomer.fetchStatus = FETCH_STATUS_SUCCESS
        draft.createCustomer.customerData = action.payload.customerData
      })
    }
    case CREATE_CUSTOMER_FAILURE: {
      return produce(state, draft => {
        draft.createCustomer.fetchStatus = FETCH_STATUS_FAILURE
        draft.createCustomer.error = action.payload.error
      })
    }
    case CREATE_CUSTOMER_IDLE: {
      return produce(state, draft => {
        draft.createCustomer.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_CUSTOMER_REQUEST: {
      return produce(state, draft => {
        draft.updateCustomer.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_CUSTOMER_SUCCESS: {
      return produce(state, draft => {
        draft.updateCustomer.fetchStatus = FETCH_STATUS_SUCCESS
        draft.updateCustomer.customerData = action.payload.customerData
      })
    }
    case UPDATE_CUSTOMER_FAILURE: {
      return produce(state, draft => {
        draft.updateCustomer.fetchStatus = FETCH_STATUS_FAILURE
        draft.updateCustomer.error = action.payload.error
      })
    }
    case UPDATE_CUSTOMER_IDLE: {
      return produce(state, draft => {
        draft.updateCustomer.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case CREATE_CUSTOMER_ADDRESS_REQUEST: {
      return produce(state, draft => {
        draft.createCustomerAddress.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_CUSTOMER_ADDRESS_SUCCESS: {
      return produce(state, draft => {
        draft.createCustomerAddress.fetchStatus = FETCH_STATUS_SUCCESS
        draft.createCustomerAddress.address = action.payload.address
      })
    }
    case CREATE_CUSTOMER_ADDRESS_FAILURE: {
      return produce(state, draft => {
        draft.createCustomerAddress.fetchStatus = FETCH_STATUS_FAILURE
        draft.createCustomerAddress.error = action.payload.error
      })
    }
    case CREATE_CUSTOMER_ADDRESS_IDLE: {
      return produce(state, draft => {
        draft.createCustomerAddress.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_CUSTOMER_ADDRESS_REQUEST: {
      return produce(state, draft => {
        draft.updateCustomerAddress.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_CUSTOMER_ADDRESS_SUCCESS: {
      return produce(state, draft => {
        draft.updateCustomerAddress.fetchStatus = FETCH_STATUS_SUCCESS
        draft.updateCustomerAddress.address = action.payload.address
      })
    }
    case UPDATE_CUSTOMER_ADDRESS_FAILURE: {
      return produce(state, draft => {
        draft.updateCustomerAddress.fetchStatus = FETCH_STATUS_FAILURE
        draft.updateCustomerAddress.error = action.payload.error
      })
    }
    case UPDATE_CUSTOMER_ADDRESS_IDLE: {
      return produce(state, draft => {
        draft.updateCustomerAddress.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_CUSTOMER_ADDRESS_REQUEST: {
      return produce(state, draft => {
        draft.deleteCustomerAddress.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_CUSTOMER_ADDRESS_SUCCESS: {
      return produce(state, draft => {
        draft.deleteCustomerAddress.fetchStatus = FETCH_STATUS_SUCCESS
        draft.deleteCustomerAddress.address = action.payload.address
      })
    }
    case DELETE_CUSTOMER_ADDRESS_FAILURE: {
      return produce(state, draft => {
        draft.deleteCustomerAddress.fetchStatus = FETCH_STATUS_FAILURE
        draft.deleteCustomerAddress.error = action.payload.error
      })
    }
    case DELETE_CUSTOMER_ADDRESS_IDLE: {
      return produce(state, draft => {
        draft.deleteCustomerAddress.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SET_DEFAULT_DELIVERY_ADDRESS_REQUEST: {
      return produce(state, draft => {
        draft.setDefaultDeliveryAddress.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS: {
      return produce(state, draft => {
        draft.setDefaultDeliveryAddress.fetchStatus = FETCH_STATUS_SUCCESS
        draft.setDefaultDeliveryAddress.address = action.payload.address
      })
    }
    case SET_DEFAULT_DELIVERY_ADDRESS_FAILURE: {
      return produce(state, draft => {
        draft.setDefaultDeliveryAddress.fetchStatus = FETCH_STATUS_FAILURE
        draft.setDefaultDeliveryAddress.error = action.payload.error
      })
    }
    case SET_DEFAULT_DELIVERY_ADDRESS_IDLE: {
      return produce(state, draft => {
        draft.setDefaultDeliveryAddress.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SET_DEFAULT_BILLING_ADDRESS_REQUEST: {
      return produce(state, draft => {
        draft.setDefaultBillingAddress.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case SET_DEFAULT_BILLING_ADDRESS_SUCCESS: {
      return produce(state, draft => {
        draft.setDefaultBillingAddress.fetchStatus = FETCH_STATUS_SUCCESS
        draft.setDefaultBillingAddress.address = action.payload.address
      })
    }
    case SET_DEFAULT_BILLING_ADDRESS_FAILURE: {
      return produce(state, draft => {
        draft.setDefaultBillingAddress.fetchStatus = FETCH_STATUS_FAILURE
        draft.setDefaultBillingAddress.error = action.payload.error
      })
    }
    case SET_DEFAULT_BILLING_ADDRESS_IDLE: {
      return produce(state, draft => {
        draft.setDefaultBillingAddress.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default customerFormPage
