import produce from "immer"

import {
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_IDLE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  createProduct: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  updateProduct: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const productFormPage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST: {
      return produce(state, draft => {
        draft.createProduct.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_PRODUCT_SUCCESS: {
      return produce(state, draft => {
        draft.createProduct.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_PRODUCT_FAILURE: {
      return produce(state, draft => {
        draft.createProduct.fetchStatus = FETCH_STATUS_FAILURE
      })
    }
    case CREATE_PRODUCT_IDLE: {
      return produce(state, draft => {
        draft.createProduct.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_PRODUCT_REQUEST: {
      return produce(state, draft => {
        draft.updateProduct.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_PRODUCT_SUCCESS: {
      return produce(state, draft => {
        draft.updateProduct.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case UPDATE_PRODUCT_FAILURE: {
      return produce(state, draft => {
        draft.updateProduct.fetchStatus = FETCH_STATUS_FAILURE
      })
    }
    case UPDATE_PRODUCT_IDLE: {
      return produce(state, draft => {
        draft.updateProduct.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default productFormPage
