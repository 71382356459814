import produce from "immer"

import {
  LOAD_LIST_PRODUCT_REQUEST,
  LOAD_LIST_PRODUCT_SUCCESS,
  LOAD_LIST_PRODUCT_FAILURE,
  LOAD_LIST_PRODUCT_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  listProduct: [],
  loadListProduct: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const productManagementPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_PRODUCT_REQUEST: {
      return produce(state, draft => {
        draft.loadListProduct.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_PRODUCT_SUCCESS: {
      return produce(state, draft => {
        draft.listProduct = action.payload.listProduct.map(productData => {
          return Object.assign({}, productData, {
            salePrice: productData.price,
            skus: productData.skus.map(data => Object.assign({}, data, { quantity: data.sku_group_join.quantity }))
          })
        }).sort((a, b) => {
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        })
        draft.loadListProduct.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case LOAD_LIST_PRODUCT_FAILURE: {
      return produce(state, draft => {
        draft.loadListProduct.fetchStatus = FETCH_STATUS_FAILURE
      })
    }
    case LOAD_LIST_PRODUCT_IDLE: {
      return produce(state, draft => {
        draft.loadListProduct.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default productManagementPage
