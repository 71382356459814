import { get, post, patch } from '../../utils'

export const GET_PURCHASE_ORDER_REQUEST = "GET_PURCHASE_ORDER_REQUEST"
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS"
export const GET_PURCHASE_ORDER_FAILURE = "GET_PURCHASE_ORDER_FAILURE"
export const GET_PURCHASE_ORDER_IDLE = "GET_PURCHASE_ORDER_IDLE"

const getPurchaseOrderRequest = () => {
  return {
    type: GET_PURCHASE_ORDER_REQUEST
  }
}

const getPurchaseOrderSuccess = (purchaseOrderData) => {
  return {
    type: GET_PURCHASE_ORDER_SUCCESS,
    payload: {
      purchaseOrderData
    }
  }
}

const getPurchaseOrderFailure = (error) => {
  return {
    type: GET_PURCHASE_ORDER_FAILURE,
    payload: {
      error
    }
  }
}

const getPurchaseOrderIdle = () => {
  return {
    type: GET_PURCHASE_ORDER_IDLE
  }
}

export const getPurchaseOrder = (billID) => {
  return async dispatch => {
    dispatch(getPurchaseOrderRequest())
    try {
      const purchaseOrderData = await get(`/order/info/${billID}`)
      dispatch(getPurchaseOrderSuccess(purchaseOrderData))
    }
    catch (err) {
      dispatch(getPurchaseOrderFailure(err))
    }
    finally {
      dispatch(getPurchaseOrderIdle())
    }
  }
}

export const CREATE_PURCHASE_ORDER_REQUEST = "CREATE_PURCHASE_ORDER_REQUEST"
export const CREATE_PURCHASE_ORDER_SUCCESS = "CREATE_PURCHASE_ORDER_SUCCESS"
export const CREATE_PURCHASE_ORDER_FAILURE = "CREATE_PURCHASE_ORDER_FAILURE"
export const CREATE_PURCHASE_ORDER_IDLE = "CREATE_PURCHASE_ORDER_IDLE"

const createPurchaseOrderRequest = () => {
  return {
    type: CREATE_PURCHASE_ORDER_REQUEST
  }
}

const createPurchaseOrderSuccess = (purchaseOrderData) => {
  return {
    type: CREATE_PURCHASE_ORDER_SUCCESS,
    payload: {
      purchaseOrderData
    }
  }
}

const createPurchaseOrderFailure = (error) => {
  return {
    type: CREATE_PURCHASE_ORDER_FAILURE,
    payload: {
      error
    }
  }
}

const createPurchaseOrderIdle = () => {
  return {
    type: CREATE_PURCHASE_ORDER_IDLE
  }
}

export const createPurchaseOrder = (data) => {
  return async dispatch => {
    dispatch(createPurchaseOrderRequest())
    try {
      const response = await post(`/order`, data)
      dispatch(createPurchaseOrderSuccess(response))
    }
    catch (err) {
      dispatch(createPurchaseOrderFailure(err))
    }
    finally {
      dispatch(createPurchaseOrderIdle())
    }
  }
}

export const UPDATE_PURCHASE_ORDER_REQUEST = "UPDATE_PURCHASE_ORDER_REQUEST"
export const UPDATE_PURCHASE_ORDER_SUCCESS = "UPDATE_PURCHASE_ORDER_SUCCESS"
export const UPDATE_PURCHASE_ORDER_FAILURE = "UPDATE_PURCHASE_ORDER_FAILURE"
export const UPDATE_PURCHASE_ORDER_IDLE = "UPDATE_PURCHASE_ORDER_IDLE"

const updatePurchaseOrderRequest = () => {
  return {
    type: UPDATE_PURCHASE_ORDER_REQUEST
  }
}

const updatePurchaseOrderSuccess = (purchaseOrderData) => {
  return {
    type: UPDATE_PURCHASE_ORDER_SUCCESS,
    payload: {
      purchaseOrderData
    }
  }
}

const updatePurchaseOrderFailure = (error) => {
  return {
    type: UPDATE_PURCHASE_ORDER_FAILURE,
    payload: {
      error
    }
  }
}

const updatePurchaseOrderIdle = () => {
  return {
    type: UPDATE_PURCHASE_ORDER_IDLE
  }
}

export const updatePurchaseOrder = (data) => {
  return async dispatch => {
    dispatch(updatePurchaseOrderRequest())
    try {
      const response = await patch(`/order/${data.id}/reserve`, data)
      dispatch(updatePurchaseOrderSuccess(response))
    }
    catch (err) {
      dispatch(updatePurchaseOrderFailure(err))
    }
    finally {
      dispatch(updatePurchaseOrderIdle())
    }
  }
}

export const updatePurchaseOrderFuture = (data) => {
  return async dispatch => {
    dispatch(updatePurchaseOrderRequest())
    try {
      const response = await patch(`/order/${data.id}/unreserve`, data)
      dispatch(updatePurchaseOrderSuccess(response))
    }
    catch (err) {
      dispatch(updatePurchaseOrderFailure(err))
    }
    finally {
      dispatch(updatePurchaseOrderIdle())
    }
  }
}
