import { get, postForm, patchForm } from '../../utils'

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST"
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS"
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE"
export const CREATE_PRODUCT_IDLE = "CREATE_PRODUCT_IDLE"

const createProductRequest = () => {
  return {
    type: CREATE_PRODUCT_REQUEST
  }
}

const createProductSuccess = (createdProduct) => {
  return {
    type: CREATE_PRODUCT_SUCCESS,
    payload: {
      createdProduct
    }
  }
}

const createProductFailure = (error) => {
  return {
    type: CREATE_PRODUCT_FAILURE,
    payload: {
      error
    }
  }
}

const createProductIdle = () => {
  return {
    type: CREATE_PRODUCT_IDLE
  }
}

export const createProduct = (data) => {
  return async dispatch => {
    dispatch(createProductRequest())
    try {
      const response = await postForm(`/sku_group`, data)
      dispatch(createProductSuccess(response))
    }
    catch (err) {
      dispatch(createProductFailure(err))
    }
    finally {
      dispatch(createProductIdle())
    }
  }
}

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE"
export const UPDATE_PRODUCT_IDLE = "UPDATE_PRODUCT_IDLE"

const updateProductRequest = () => {
  return {
    type: UPDATE_PRODUCT_REQUEST
  }
}

const updateProductSuccess = (createdProduct) => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: {
      createdProduct
    }
  }
}

const updateProductFailure = (error) => {
  return {
    type: UPDATE_PRODUCT_FAILURE,
    payload: {
      error
    }
  }
}

const updateProductIdle = () => {
  return {
    type: UPDATE_PRODUCT_IDLE
  }
}

export const updateProduct = (data) => {
  return async dispatch => {
    dispatch(updateProductRequest())
    try {
      const response = await patchForm(`/sku_group/${data.id}`, data)
      dispatch(updateProductSuccess(response))
    }
    catch (err) {
      dispatch(updateProductFailure(err))
    }
    finally {
      dispatch(updateProductIdle())
    }
  }
}
