import { get } from '../../utils'

export const LOAD_LIST_PRODUCT_REQUEST = "LOAD_LIST_PRODUCT_REQUEST"
export const LOAD_LIST_PRODUCT_SUCCESS = "LOAD_LIST_PRODUCT_SUCCESS"
export const LOAD_LIST_PRODUCT_FAILURE = "LOAD_LIST_PRODUCT_FAILURE"
export const LOAD_LIST_PRODUCT_IDLE = "LOAD_LIST_PRODUCT_IDLE"

const loadListProductRequest = () => {
  return {
    type: LOAD_LIST_PRODUCT_REQUEST
  }
}

const loadListProductSuccess = (listProduct) => {
  return {
    type: LOAD_LIST_PRODUCT_SUCCESS,
    payload: {
      listProduct
    }
  }
}

const loadListProductFailure = () => {
  return {
    type: LOAD_LIST_PRODUCT_FAILURE
  }
}

const loadListProductIdle = () => {
  return {
    type: LOAD_LIST_PRODUCT_IDLE
  }
}

export const loadListProduct = (data) => {
  return async dispatch => {
    dispatch(loadListProductRequest())
    try {
      const response = await get(`/sku_group`)      
      dispatch(loadListProductSuccess(response))
    }
    catch (err) {
      dispatch(loadListProductFailure(err))
    }
    finally {
      dispatch(loadListProductIdle())
    }
  }
}
