import produce from "immer"
import moment from 'moment'
import Decimal from 'decimal.js'

import {
  LOAD_LIST_STOCK_DATE_REQUEST,
  LOAD_LIST_STOCK_DATE_SUCCESS,
  LOAD_LIST_STOCK_DATE_FAILURE,
  LOAD_LIST_STOCK_DATE_IDLE,
  CREATE_STOCK_LOT_DATE_REQUEST,
  CREATE_STOCK_LOT_DATE_SUCCESS,
  CREATE_STOCK_LOT_DATE_FAILURE,
  CREATE_STOCK_LOT_DATE_IDLE,
  UPDATE_STOCK_LOT_REQUEST,
  UPDATE_STOCK_LOT_SUCCESS,
  UPDATE_STOCK_LOT_FAILURE,
  UPDATE_STOCK_LOT_IDLE,
  LOAD_LIST_STOCK_LOG_REQUEST,
  LOAD_LIST_STOCK_LOG_SUCCESS,
  LOAD_LIST_STOCK_LOG_FAILURE,
  LOAD_LIST_STOCK_LOG_IDLE,
  CREATE_STOCK_LOG_REQUEST,
  CREATE_STOCK_LOG_SUCCESS,
  CREATE_STOCK_LOG_FAILURE,
  CREATE_STOCK_LOG_IDLE,
} from './actions'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_IDLE
} from '../../utils'

const initialState = {
  listStockDate: [],
  listStockLog: [],
  loadListStockDate: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  loadListStockLog: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  createStockLotDate: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  updateStockLot: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  },
  createStockLog: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined
  }
}

const stockCostPage = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_STOCK_DATE_REQUEST: {
      return produce(state, draft => {
        draft.loadListStockDate.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_STOCK_DATE_SUCCESS: {
      const prepareListStockDate = action.payload.listStockDate.map(data => {
        const { totalComming, totalExpired } = data.stocks.reduce((prev, current) => {
          return Object.assign({}, prev, {
            totalComming: new Decimal(prev.totalComming).add(current.in_coming).toNumber(),
            totalExpired: new Decimal(prev.totalExpired).add(current.expires).toNumber()
          })
        }, { totalComming: 0, totalExpired: 0 })
        return Object.assign({}, data, {
          totalComming, totalExpired
        })
      })
      return produce(state, draft => {
        draft.listStockDate = prepareListStockDate.sort((a, b) => moment(b.model_date).toDate() - moment(a.model_date).toDate())
        draft.loadListStockDate.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case LOAD_LIST_STOCK_DATE_FAILURE: {
      return produce(state, draft => {
        draft.loadListStockDate.fetchStatus = FETCH_STATUS_FAILURE
        draft.loadListStockDate.error = action.payload.error
      })
    }
    case LOAD_LIST_STOCK_DATE_IDLE: {
      return produce(state, draft => {
        draft.loadListStockDate.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_STOCK_LOG_REQUEST: {
      return produce(state, draft => {
        draft.loadListStockLog.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_STOCK_LOG_SUCCESS: {
      return produce(state, draft => {
        draft.listStockLog = action.payload.listStockLog.sort((a, b) => moment(b.created_at).toDate() - moment(a.created_at).toDate())
        draft.loadListStockLog.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case LOAD_LIST_STOCK_LOG_FAILURE: {
      return produce(state, draft => {
        draft.loadListStockLog.fetchStatus = FETCH_STATUS_FAILURE
        draft.loadListStockLog.error = action.payload.error
      })
    }
    case LOAD_LIST_STOCK_LOG_IDLE: {
      return produce(state, draft => {
        draft.loadListStockLog.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case CREATE_STOCK_LOT_DATE_REQUEST: {
      return produce(state, draft => {
        draft.createStockLotDate.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_STOCK_LOT_DATE_SUCCESS: {
      return produce(state, draft => {
        draft.createStockLotDate.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_STOCK_LOT_DATE_FAILURE: {
      return produce(state, draft => {
        draft.createStockLotDate.fetchStatus = FETCH_STATUS_FAILURE
        draft.createStockLotDate.error = action.payload.error
      })
    }
    case CREATE_STOCK_LOT_DATE_IDLE: {
      return produce(state, draft => {
        draft.createStockLotDate.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_STOCK_LOT_REQUEST: {
      return produce(state, draft => {
        draft.updateStockLot.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_STOCK_LOT_SUCCESS: {
      return produce(state, draft => {
        draft.updateStockLot.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case UPDATE_STOCK_LOT_FAILURE: {
      return produce(state, draft => {
        draft.updateStockLot.fetchStatus = FETCH_STATUS_FAILURE
        draft.updateStockLot.error = action.payload.error
      })
    }
    case UPDATE_STOCK_LOT_IDLE: {
      return produce(state, draft => {
        draft.updateStockLot.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case CREATE_STOCK_LOG_REQUEST: {
      return produce(state, draft => {
        draft.createStockLog.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_STOCK_LOG_SUCCESS: {
      return produce(state, draft => {
        draft.createStockLog.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_STOCK_LOG_FAILURE: {
      return produce(state, draft => {
        draft.createStockLog.fetchStatus = FETCH_STATUS_FAILURE
        draft.createStockLog.error = action.payload.error
      })
    }
    case CREATE_STOCK_LOG_IDLE: {
      return produce(state, draft => {
        draft.createStockLog.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default stockCostPage
